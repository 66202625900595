import client from "./index";
import { routes } from "./../_api/routes";
import { ERROR, OFFER_RECEIVED, PRODUCTS } from "./types";
import { extractServerErrors } from "../_utils/validate";

export const getProducts = id => async dispatch => {
  try {
    const response = await client.get(`${routes.creditorProduct}/${id}`);
    dispatch({
      type: PRODUCTS,
      payload: response.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};
export const getOfferReceived = (
  application_id,
  product_id
) => async dispatch => {
  try {
    const response = await client.get(
      `${routes.productStatus}/${application_id}/products/${product_id}`
    );
    dispatch({
      type: OFFER_RECEIVED,
      payload: response.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};
export const productStatus = (
  application_id,
  product_id,
  status,
  callback
) => async dispatch => {
  try {
     await client.put(
      `${routes.productStatus}/${application_id}/products/${product_id}`,
      { status }
    );
    callback();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};
