import React, { Component, Fragment } from "react";
import Translate from "react-translate-component";
import { Link } from "react-router-dom";
import { confirmationLink } from "./confirmationLink";
import { connect } from "react-redux";
import LanguageFlags from "../components/general/LanguageFlags";
import Sidebar from "../components/general/Sidebar";
import { SidebarContext } from "../components/general/SidebarContext";
import DashboardImage1 from "../img/DashImage-1.png";
import DashboardImage2 from "../img/DashImage-2.png";
import CheckIcon from "../img/check-icon-white.svg";
import CloseIcon from "../img/close-icon-white.svg";

class Dashboard extends Component {
  render() {
    return (
      <>
        <Fragment>
          <div className="w-100 content-head row align-items-start mt-5 ml-0 mr-0">
            <div className="content-head-left w-100 col-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              {/*<div*/}
              {/*  className="w-100 alert alert-success"*/}
              {/*  // style={{ display: "flex" }}*/}
              {/*  style={{ display: "none" }}*/}
              {/*>*/}
              {/*  <img src={CheckIcon} width="24" />*/}
              {/*  <div className="w-100 ml-3">*/}
              {/*    <p className="mb-0" style={{ fontWeight: "700" }}>*/}
              {/*      Many Thanks! We have received your loan application. You*/}
              {/*      will shortly receive an email with our offer.*/}
              {/*    </p>*/}
              {/*    <p className="mb-0" style={{ fontSize: "0.75rem" }}>*/}
              {/*      You can edit the application at any time under "My Created*/}
              {/*      Application" and send it again.*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*  <img className="flex flex-end" src={CloseIcon} width="24" />*/}
              {/*</div>*/}
              <h4 className="font-weight-bold">
                <Translate content="label.wow" />
              </h4>
            </div>
          </div>
          <div className="row content-body mt-5">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="card-box">
                <img src={DashboardImage1} width="100%" />
                <div className="card-body">
                  <ul className="pl-3">
                    <li className="pb-3">
                      <Translate content="text_blocks.creatYourLoan" />
                    </li>
                    <li className="pb-3">
                      <Translate content="text_blocks.createYourLoanTailored" />
                    </li>
                  </ul>
                  <div>
                    <Link className="btn btn-primary" to="/apply-for-loan">
                      <Translate content="sidebar.apply_for_loan" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="card-box">
                <img src={DashboardImage2} width="100%" />
                <div className="card-body">
                  <ul className="pl-3">
                    <li className="pb-3">
                      <Translate content="text_blocks.viewList" />
                    </li>
                    <li className="pb-3">
                      <Translate content="text_blocks.editLoanRequest" />
                    </li>
                  </ul>
                  <div>
                    <Link className="btn btn-primary" to="/list-application">
                      <Translate content="label.application" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </>
    );
  }
}

export default Dashboard;
