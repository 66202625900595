import { FETCH_INDUSTRIES } from "actions/types";

const initialState = {
  industries: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_INDUSTRIES:
      return {
        ...state,
        industries: action.payload
      };

    default:
      return state;
  }
}
