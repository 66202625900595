import { reducer as formReducer } from "redux-form";
import {
  CLEAR_PAGEONE_FORM,
  CLEAR_PAGETHREE_FORM,
  CLEAR_PAGETWO_FORM,
  CLEAR_PAGEFOUR_FORM,
  CLEAR_PAGEONEEDIT_FORM,
  CLEAR_PAGETHREEEDIT_FORM,
  CLEAR_PAGETWOEDIT_FORM,
  CLEAR_PAGEFOUREDIT_FORM,
} from "../actions/types";

export default formReducer.plugin({
  PageOne: (state, action) => {
    switch (action.type) {
      case CLEAR_PAGEONE_FORM:
        return undefined;
      default:
        return state;
    }
  },
  PageTwo: (state, action) => {
    switch (action.type) {
      case CLEAR_PAGETWO_FORM:
        return undefined;
      default:
        return state;
    }
  },
  PageThree: (state, action) => {
    switch (action.type) {
      case CLEAR_PAGETHREE_FORM:
        return undefined;
      default:
        return state;
    }
  },
  PageFour: (state, action) => {
    switch (action.type) {
      case CLEAR_PAGEFOUR_FORM:
        return undefined;
      default:
        return state;
    }
  },

  PageOneEdit: (state, action) => {
    switch (action.type) {
      case CLEAR_PAGEONEEDIT_FORM:
        return undefined;
      default:
        return state;
    }
  },
  PageTwoEdit: (state, action) => {
    switch (action.type) {
      case CLEAR_PAGETWOEDIT_FORM:
        return undefined;
      default:
        return state;
    }
  },
  PageThreeEdit: (state, action) => {
    switch (action.type) {
      case CLEAR_PAGETHREEEDIT_FORM:
        return undefined;
      default:
        return state;
    }
  },
  PageFourEdit: (state, action) => {
    switch (action.type) {
      case CLEAR_PAGEFOUREDIT_FORM:
        return undefined;
      default:
        return state;
    }
  },
});
