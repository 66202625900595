import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {
  checkBoxTermsConditions,
  inputField,
  radioButton,
  renderPhone,
} from "../../_formFields";
import * as validation from "../../_utils/validate";
import { displayServerErrors } from "../../_utils/validate";
import { signup } from "../../actions/signup";
import { clearError, clearLoading } from "../../actions/clearError";
import { connect } from "react-redux";
import { compose } from "redux";

import Translate from "react-translate-component";
import * as flashMessage from "../../actions/flashMessage";
import Loading from "../general/Loading";
import ReactTooltip from "react-tooltip";
import LanguageFlags from "../general/LanguageFlags";
import { fetchSuggestions } from "_utils/fetchSuggestions";

export const LIMIT = 9;

class Signup extends Component {
  state = {
    phone: "",
    loading: false,
    suggestions: [],
  };

  componentDidMount() {
    this.props.clearError();
    this.props.clearLoading();
  }

  onSubmit = (formProps) => {
    const fetchedDetails =
      this.state.suggestions[formProps.suggestionIndexValue];

    if (fetchedDetails && fetchedDetails.registered_address) {
      formProps.company_number = fetchedDetails.company_number;

      formProps.street_address =
        fetchedDetails.registered_address.street_address;
      formProps.headquarter =
        fetchedDetails.registered_address.region ||
        fetchedDetails.registered_address.locality;
      formProps.zip_code = fetchedDetails.registered_address.postal_code;

      // && germany
      if (
        !formProps.region &&
        !formProps.postal_code &&
        fetchedDetails.jurisdiction_code === "de"
      ) {
        const re = /(\s)?[0-9]{5}(\s)?/;

        const full_address = fetchedDetails.registered_address_in_full;
        const stringArr = full_address.split(",");
        const streetAddress = stringArr[0];
        if (stringArr.length === 2) {
          const postalAddress = re.exec(stringArr[1])?.[0];
          if (postalAddress) {
            const index = stringArr[1].indexOf(postalAddress) > 0 ? 0 : 1;
            const region = stringArr[1].split(postalAddress)[index];
            formProps.street_address = streetAddress;
            formProps.headquarter = region;
            formProps.zip_code = postalAddress.trim();
          }
        }
      }
    } else {
      delete formProps.company_number;
      delete formProps.street_address;
      delete formProps.headquarter;
      delete formProps.zip_code;
    }

    this.props.signup(formProps, () => {
      this.props.history.push("/signup/confirm-email");
    });
  };

  onBlurFetchSuggestions = async (value) => {
    try {
      this.props.change("suggestionIndexValue", undefined);
      this.setState((prevState) => {
        return {
          ...prevState,
          loading: true,
        };
      });
      const suggestions = await fetchSuggestions(value.target.value);

      this.setState((prevState) => {
        return {
          ...prevState,
          loading: false,
          suggestions,
        };
      });
    } catch (e) {
      this.setState((prevState) => {
        return {
          ...prevState,
          loading: false,
        };
      });
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container-full-width">
        <div className="panel-container">
          <div className="row w-100">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 feature-container feature-container--signup">
              <a href=" https://www.pihub-pi.com/de/" target="_blank">
                <img
                  className="company-logo"
                  src="/assets/img/logo.png"
                  alt="company logo"
                />
              </a>

              <div className="feature-sidebar">
                <div className="feature-ours">
                  <Translate
                    content="text_blocks.signUpTitle"
                    component="h3"
                    className="feature-ours__title"
                  />
                  <Translate
                    content="text_blocks.signUpReason"
                    component="p"
                    className="feature-ours__sub-title"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 main-container">
              <div className="signup-form-container">
                <nav className="header-actions">
                  <LanguageFlags />
                </nav>
                <Loading />
                <header className="page-header">
                  <Translate
                    content="text_blocks.signUpAs"
                    component="h1"
                    className="page-title"
                  />
                  <Translate
                    content="text_blocks.detailsBelow"
                    component="p"
                    className="page-desc"
                  />
                </header>
                <form
                  className="form-signup"
                  onSubmit={handleSubmit(this.onSubmit)}
                >
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label>
                          <Translate content="label.first_name" />
                        </label>
                        <i
                          data-tip
                          data-for="first_name"
                          className=" bx bx-info-circle ml-2"
                        >
                          <ReactTooltip
                            id="first_name"
                            type="info"
                            className="tooltip-width"
                          >
                            <Translate content="tooltip.first_name" />
                          </ReactTooltip>
                        </i>
                        <Field
                          name="fname"
                          type="text"
                          component={inputField}
                          className="form-control"
                          validate={validation.required}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label>
                          <Translate content="label.last_name" />
                        </label>
                        <i
                          data-tip
                          data-for="last_name"
                          className=" bx bx-info-circle ml-2"
                        >
                          <ReactTooltip
                            id="last_name"
                            type="info"
                            className="tooltip-width"
                          >
                            <Translate content="tooltip.last_name" />
                          </ReactTooltip>
                        </i>
                        <Field
                          name="lname"
                          type="text"
                          component={inputField}
                          className="form-control"
                          validate={validation.required}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      <Translate content="label.company_name" />
                    </label>
                    <i
                      data-tip
                      data-for="company_name"
                      className=" bx bx-info-circle ml-2"
                    >
                      <ReactTooltip
                        id="company_name"
                        type="info"
                        className="tooltip-width"
                      >
                        <Translate content="tooltip.company_name" />
                      </ReactTooltip>
                    </i>
                    <Field
                      name="company_name"
                      type="text"
                      onBlur={this.onBlurFetchSuggestions}
                      component={inputField}
                      className="form-control"
                      validate={validation.required}
                    />
                  </div>

                  {this.state.loading && <div class="loader"></div>}
                  {/* <Spinner /> */}

                  {this.state.suggestions.length > 0 && !this.state.loading && (
                    <div>
                      <div className="form-group mb-0">
                        <label>
                          <Translate content="placeholder.please_select_your_company" />

                          {/* Please select your company */}
                          {/* <Translate content="label.company_name" /> */}
                        </label>
                      </div>

                      <div className="form-group mb-3 select-company">
                        {this.state.suggestions.map((suggestion, index) => {
                          if (index > LIMIT - 1) return null;
                          return (
                            <>
                              <div
                                className="form-check form-check-inline col-6 col-sm-6 col-md-6 col-lg-4 mr-0 mb-3"
                                key={index}
                              >
                                <Field
                                  type="radio"
                                  component={radioButton}
                                  value={index.toString()}
                                  name="suggestionIndexValue"
                                  className="form-check-input mr-2"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="rating_for_credit"
                                >
                                  <div>{suggestion.company_number}</div>
                                  <div>{suggestion.name}</div>
                                  <div>
                                    {suggestion.registered_address_in_full}
                                  </div>{" "}
                                </label>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div className="form-group mt-1">
                    <label>
                      <Translate content="label.email_address" />
                    </label>
                    <i
                      data-tip
                      data-for="email_address"
                      className=" bx bx-info-circle ml-2"
                    >
                      <ReactTooltip
                        id="email_address"
                        type="info"
                        className="tooltip-width"
                      >
                        <Translate content="tooltip.email_address" />
                      </ReactTooltip>
                    </i>
                    <Field
                      name="email"
                      type="email"
                      component={inputField}
                      className="form-control"
                      validate={validation.required}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      <Translate content="label.password" />
                    </label>
                    <i
                      data-tip
                      data-for="password"
                      className=" bx bx-info-circle ml-2"
                    >
                      <ReactTooltip
                        id="password"
                        type="info"
                        className="tooltip-width"
                      >
                        <Translate content="tooltip.password" />
                      </ReactTooltip>
                    </i>
                    <Field
                      name="password"
                      type="password"
                      component={inputField}
                      className="form-control"
                      validate={validation.required}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="password_confirmation"
                      type="password"
                      component={inputField}
                      label={<Translate content="label.confirm_password" />}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      <Translate content="label.phone_number" />
                    </label>
                    <i
                      data-tip
                      data-for="phone_number"
                      className=" bx bx-info-circle ml-2"
                    >
                      <ReactTooltip
                        id="phone_number"
                        type="info"
                        className="tooltip-width"
                      >
                        <Translate content="tooltip.phone_number" />
                      </ReactTooltip>
                    </i>
                    <Field
                      component={renderPhone}
                      regions="europe"
                      placeholder="+49 30 12345-678"
                      name="phone_number"
                      inputExtraProps={{
                        name: "phone_number",
                        required: true,
                      }}
                      country="de"
                      validate={validation.required}
                    />
                  </div>
                  <Field
                    name="agreed_term"
                    component={checkBoxTermsConditions}
                    type="checkbox"
                    className="mr-1"
                  />
                  {this.props.errMsg
                    ? displayServerErrors(this.props.errMsg)
                    : null}
                  <Translate
                    content="button.signup"
                    className="btn btn-primary btn-form"
                    type="submit"
                    component="button"
                  />
                </form>
                <br />
                <Translate content="text_blocks.alreadyAMember" />
                &nbsp;
                <Link to="/">
                  <strong>
                    <Translate content="button.login" />
                  </strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (values.email) {
    errors.email = validation.email(values.email);
  }
  if (values.password) {
    errors.password = validation.password(values.password);
    if (values.password !== values.password_confirmation) {
      errors.password_confirmation = validation.same(
        values.password_confirmation
      );
    }
  }
  if (!values.agreed_term) {
    errors.agreed_term = "validation.pleaseAcceptTermsAndCondition";
  }
  return errors;
}

function mapStateToProps(state) {
  return { errMsg: state.errors, formValues: state.form.signup?.values };
}

export default compose(
  connect(mapStateToProps, {
    signup,
    clearError,
    clearLoading,
    ...flashMessage,
  }),
  reduxForm({
    validate,
    form: "signup",
  })
)(Signup);
