import { HIDE_FLASH_MESSAGE, SHOW_FLASH_MESSAGE } from "./types";

export const showFlashMessage = (message) => async (dispatch) => {
  dispatch({
    type: SHOW_FLASH_MESSAGE,
    payload: { message: message },
  });
};

export const hideFlashMessage = () => async (dispatch) => {
  dispatch({
    type: HIDE_FLASH_MESSAGE,
    payload: null,
  });
};
