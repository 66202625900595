import React from "react";
import { Link, withRouter } from "react-router-dom";
import Translate from "react-translate-component";
import { SidebarContext } from "./SidebarContext";

class Sidebar extends React.Component {
  static contextType = SidebarContext;

  render() {
    return (
      <div className={`sidebar ${this.context.sidebar}`}>
        <div className={`hamburger ${this.context.sidebar}`}>
          <div
            className="hamburger-bar--close"
            onClick={this.context.toggleSidebar}
          ></div>
        </div>
        <nav className="nav-sidebar">
          <ul className="menu-sidebar">
            <li className="current-menu dashMenu">
              <Link to="/Dashboard">
                <i className="bx bx-dashboard" />
                <Translate content="sidebar.dashboard" />
              </Link>
            </li>
            <li className="current-menu has-sub-menu">
              <Link
                to="#"
                rel="noopener noreferrer"
                id="dropdown-menu"
                data-toggle="collapse"
                data-target="#collapseMenu"
                aria-expanded="true"
                aria-controls="collapseMenu"
              >
                <i className="bx bx-dollar-circle" />
                <Translate content="sidebar.application" />
              </Link>
              <ul
                id="collapseMenu"
                className={`sub-menu collapse show`}
                aria-labelledby="headingOne"
              >
                <li
                  className={
                    this.props.location.pathname === "/apply-for-loan"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/apply-for-loan" rel="noopener noreferrer">
                    <Translate content="sidebar.apply_for_loan" />
                  </Link>
                </li>

                <li
                  className={
                    this.props.location.pathname === "/list-application"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/list-application" rel="noopener noreferrer">
                    <Translate content="sidebar.my_application" />
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(Sidebar);
