import React, { Component } from "react";
import Translate from "react-translate-component";
import ReactToolTip from "react-tooltip";
import { Field } from "redux-form";
import { dropDownField, textAreaField } from "../../../_formFields";
import * as validation from "../../../_utils/validate";

import {
  displayServerErrors,
  formSubmitFailError,
} from "./../../../_utils/validate";

const alternateIds = [2, 10, 12, 14, 31, 34];

class PageTwoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceOption: [],
      statesOptions: [],
      countiesOptions: [],
    };
  }

  async componentDidMount() {
    // this.props.clearError();
    this.props.clearLoading();
    await this.props.fetchStates();
    await this.props.fetchServices();

    await this.props.fetchIndustries();

    const lang = this.props?.language || "de";

    let statesOptions = [];
    let states = [];
    let industryOptions = [];
    let industries = [...this.props.industries.industries];

    industries.map((industry, id) =>
      industryOptions.push({ label: industry.name[lang], value: industry.id })
    );

    const alternateIndustryOptions = industryOptions?.filter((e) =>
      alternateIds.includes(e?.value)
    );

    if (typeof this.props.states !== "undefined") {
      states = [...this.props.states];
    }
    states.map((st, id) =>
      statesOptions.push({ label: st.name, value: st.id })
    );
    let serviceOptions = [];
    let services = [...this.props.services.services];
    services.map((service, id) =>
      serviceOptions.push({ label: service.name[lang], value: service.id })
    );

    this.setState({
      industryOptions: industryOptions,
      statesOptions: statesOptions,
      serviceOptions: serviceOptions,
      alternateIndustryOptions: alternateIndustryOptions,
    });
  }

  async componentDidUpdate(prevProps) {
    if (
      typeof this.props.selectedState !== "undefined" &&
      this.props.selectedState !== prevProps.selectedState
    ) {
      await this.props.fetchCounties(this.props.selectedState.value);

      let counties = [...this.props.counties];
      let countyOptions = [];
      counties.map((county, id) =>
        countyOptions.push({ label: county.name, value: county.id })
      );
      this.setState({
        countiesOptions: countyOptions,
      });
    }
  }

  onChangeService = (field) => {
    if (field.value === 5) {
      this.props.change("industry", null);
    }
  };

  render() {
    const { handleSubmit, pageBackChange, onPageChange } = this.props;

    return (
      <form className="form-signup" onSubmit={handleSubmit(onPageChange)}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="form-group">
              <label>
                <Translate content="label.creditType" />
              </label>
              <i
                data-tip
                data-for="service_id"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="service_id"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate content="tooltip.service_id" />
                </ReactToolTip>
              </i>
              <Field
                name="service_id"
                component={dropDownField}
                options={this.state.serviceOptions}
                validate={validation.required}
                onChange={this.onChangeService}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <div className="form-group">
              <label>
                <Translate content="label.industry" />
              </label>
              <i
                data-tip
                data-for="industry"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="industry"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate content="tooltip.industry" />
                </ReactToolTip>
              </i>
              <Field
                name="industry"
                component={dropDownField}
                options={
                  this.props.serviceAlternate
                    ? this.state.alternateIndustryOptions
                    : this.state.industryOptions
                }
                validate={validation.required}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Translate content="label.states" />
              </label>
              <i data-tip data-for="states" className=" bx bx-info-circle ml-2">
                <ReactToolTip id="states" type="info" className="tooltip-width">
                  <Translate content="tooltip.states" />
                </ReactToolTip>
              </i>
              <Field
                name="states"
                component={dropDownField}
                options={this.state.statesOptions}
                validate={validation.required}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Translate content="label.county" />
              </label>
              <i data-tip data-for="county" className=" bx bx-info-circle ml-2">
                <ReactToolTip id="county" type="info" className="tooltip-width">
                  <Translate content="tooltip.county" />
                </ReactToolTip>
              </i>
              <Field
                name="county"
                component={dropDownField}
                options={this.state.countiesOptions}
                validate={validation.required}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>
                <Translate content="label.purpose" />
              </label>
              <i
                data-tip
                data-for="purpose"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="purpose"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate content="tooltip.purpose" />
                </ReactToolTip>
              </i>
              <Field
                name="description"
                className="purposeTextArea"
                component={textAreaField}
                validate={validation.required}
                readOnly={this.props.pageEdit ? true : false}
                cols="10"
                rows="7"
                style={{ padding: "10px" }}
              />
            </div>
          </div>
        </div>
        {this.props.errMsg ? displayServerErrors(this.props.errMsg) : null}
        {formSubmitFailError(this.props.submitFailed)}
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 mt-4 backBtn">
            <button
              className="btn btn-secondary"
              onClick={pageBackChange}
              type="button"
            >
              <Translate content="label.back" />
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6 mt-4">
            <button
              className="btn btn-primary"
              style={{ float: "right" }}
              type="submit"
            >
              <Translate content="label.continue" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default PageTwoForm;
