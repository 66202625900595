import client from "./index";
import { routes } from "./../_api/routes";
import { ERROR, AUTH_USER } from "../actions/types";
import { extractServerErrors } from "../_utils/validate";

export const getTokenForEmail = (email, callback) => async dispatch => {
  try {
    const response = await client.post(routes.passwordReset, email);
    callback(response.data.token);
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};

export const changePasswordWithToken = (
  details,
  callback
) => async dispatch => {
  try {
    await client.post(routes.changePasswordWithToken, details);
    localStorage.removeItem("token");
    dispatch({
      type: AUTH_USER,
      payload: undefined
    });
    callback();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};
