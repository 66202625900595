import React, { Component } from "react";
import { reduxForm } from "redux-form";

import { connect } from "react-redux";

import PageOneForm from "../forms/PageOneForm";

class PageOneEdit extends Component {
  render() {
    return <PageOneForm {...this.props} pageEdit={true} />;
  }
}

PageOneEdit = reduxForm({
  form: "PageOneEdit",
  destroyOnUnmount: false,
})(PageOneEdit);

const mapStateToProps = (state) => {
  const oldStateValues = { ...state?.form?.PageOneEdit };
  const language = state?.language;

  const applicationDetails = state?.fetchApplication?.application;

  const initialValues = {
    financial_need: applicationDetails?.financial_need,
    time_duration: applicationDetails?.time_duration,
    deadline: applicationDetails?.deadline,
    payment_after: applicationDetails?.payment_after,
  };

  return {
    language,
    oldStateValues,
    errMsg: state.errors,
    initialValues: { ...initialValues },
  };
};

export default connect(mapStateToProps, {})(PageOneEdit);
