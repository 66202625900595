import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { Multiselect, DateTimePicker } from "react-widgets/cjs";
import Dropzone from "react-dropzone";
import Translate from "react-translate-component";
import momentLocalizer from "react-widgets-moment";
import moment from "moment";
import ReactPhoneInput from "react-phone-input-2";
import InputFieldError from "../components/error/InputFieldError";
import "react-phone-input-2/lib/style.css";
import RDropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import InputRange from "react-input-range";
import "react-widgets/styles.css";

new momentLocalizer(moment);

export const inputField = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  max,
  min,
  step,
  hideErrorMessage,
  meta: { error, touched },
}) => {
  return (
    <div>
      {label ? (
        <label>
          <strong>{label}</strong>
        </label>
      ) : null}
      <Translate
        {...input}
        component="input"
        type={type}
        color={"white"}
        className={className}
        id={id}
        min={min}
        max={max}
        step={step}
        attributes={{ placeholder: placeholder }}
      />
      {!hideErrorMessage && (
        <InputFieldError
          touched={touched}
          error={<Translate content={error} />}
        />
      )}
    </div>
  );
};

export const textAreaField = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  readOnly,
  cols,
  rows,
  meta: { error, touched },
}) => {
  return (
    <div>
      <label>{label}</label>
      <textarea
        {...input}
        type={type}
        color={"white"}
        className={className}
        id={id}
        placeholder={placeholder}
        cols={cols}
        rows={rows}
        readOnly={readOnly}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const checkBox = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched },
}) => {
  return (
    <div className="form-check">
      <input
        {...input}
        type={type}
        color={"white"}
        className={className}
        id={id}
        placeholder={placeholder}
      />
      &nbsp;
      <label className="form-check-label">{label}</label>
      <p>
        <InputFieldError
          touched={touched}
          error={<Translate content={error} />}
        />
      </p>
    </div>
  );
};

export const dropDownField = ({
  options,
  input,
  label,
  type,
  className,
  readOnly,
  id,
  placeholder,
  valueField,
  textField,
  meta: { error, touched },
}) => {
  return (
    <div className="form-group">
      {/* <label>{label}</label> */}
      <Select
        {...input}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        className={className}
        valueField={valueField}
        textField={textField}
        readOnly={readOnly}
        // placeholder={<Translate content="placeholder.select" />}
        placeholder={placeholder || <Translate content="placeholder.select" />}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const inputSlider = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  max,
  min,
  step,
  defaultValue,
  meta: { error, touched },
}) => {
  return (
    <Fragment>
      <label>{label}</label>
      <input
        {...input}
        className={className}
        id={id}
        placeholder={placeholder}
        type={type}
        min={min}
        max={max}
        step={step}
        data-orientation="horizontal"
        value={defaultValue}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

export const renderMultiselect = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched },
  data,
  valueField,
  textField,
}) => {
  return (
    <Fragment>
      <label htmlFor="">{label}</label>
      <Multiselect
        {...input}
        onBlur={() => input.onBlur()}
        value={input.value || []}
        data={data}
        valueField={valueField}
        textField={textField}
        className={className}
        id={id}
        placeholder="Select Tags"
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

export const renderDropzoneField = ({
  label,
  input,
  name,
  id,
  meta: { touched, error },
}) => {
  return (
    <Fragment>
      <label>{label}</label>
      <Dropzone
        onDrop={(filesToUpload) => {
          input.onChange(filesToUpload);
        }}
        maxSize={8000000}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="file-uploader file-uploader--small dropzone"
          >
            <div className="dz-message">
              <img src="/assets/img/icons/bx-cloud-upload.png" alt="" />
              <p>
                {/* <a >Add file </a> */}
                <Translate
                  content="label.addFile"
                  component="a"
                  className="font-weight-bold mr-1"
                />
                {/* <span>or drop files here</span> */}
                <Translate content="label.orDrop" />
              </p>
              <div className="fallback" />
            </div>
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

export const radioButton = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched },
}) => {
  return (
    <div>
      <label>{label}</label>
      <input
        {...input}
        type="radio"
        color={"white"}
        className={className}
        id={id}
        placeholder={placeholder}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const inputDoubleSlider = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  max,
  min,
  meta: { error, touched },
}) => {
  return (
    <Fragment>
      <label>{label}</label>

      <div className="demo col-md-9 col-sm-8 col-8">
        <InputRange
          maxValue={max}
          minValue={min}
          value={{ max: max, min: min }}
          onChange={(value) => {}}
        />
      </div>

      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

export const renderDatePicker = ({
  label,
  selectedValue,
  input: { onChange, value },
  onCurrentDateChange,
  showTime,
  required,
  minDate,
  currentDate,
  disabled,
  meta: { touched, error, warning },
}) => (
  <div>
    {/* <label>{label}</label> */}
    <div>
      <DateTimePicker
        onChange={onChange}
        format="DD.MM.YYYY"
        value={
          !value ? (selectedValue ? selectedValue : null) : new Date(value)
        }
        min={minDate ? minDate : new Date()}
        required={required}
        defaultCurrentDate={currentDate}
        disabled={disabled}
        // onCurrentDateChange={onCurrentDateChange}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  </div>
);

export const renderPhone = ({
  label,
  input: { onChange, value },
  inputExtraProps: { name, required },
  regions,
  placeholder,
  country,
  meta: { touched, error },
}) => (
  <div>
    <div>
      <ReactPhoneInput
        inputExtraProps={{
          name: name,
          required: required,
        }}
        onChange={onChange}
        value={value}
        regions={regions}
        placeholder={placeholder}
        country={country}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  </div>
);

export const ReactUploader = ({
  input,
  meta: { touched, error },
  initialFiles,
}) => {
  let uploadedFiles = initialFiles ? initialFiles : [];
  const handleChangeStatus = (files, status) => {
    switch (status) {
      case "done":
        if (
          !uploadedFiles.find(
            (uploadedFile) => uploadedFile.name === files.file.name
          )
        ) {
          uploadedFiles.push(files.file);
        }
        break;
      case "removed":
        uploadedFiles.forEach((uploadedFile, index) => {
          if (uploadedFile.name === files.file.name) {
            uploadedFiles.splice(index, 1);
          }
        });
        break;
      default:
    }

    input.onChange(uploadedFiles);
  };

  const Layout = ({
    input,
    previews,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }) => {
    return (
      <div style={{ display: "flex" }}>
        <div className="col-md-6 pl-0">
          <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
        </div>
        <div className="col-md-6">
          <div className="pb-3" style={{ fontSize: "0.75rem" }}></div>
          <div>
            {files.length !== 0 && (
              <div className="dropzone-preview">{previews}</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const CustomInput = ({ accept, files, onFiles, getFilesFromEvent }) => {
    const text =
      files.length > 0 ? (
        <Translate content="label.addFileorDrop" />
      ) : (
        <Translate content="label.addFileorDrop" />
      );

    return (
      <label className="fileupload-label">
        <div className="fileupload-label-content">
          <img
            src="/assets/img/icons/bx-cloud-upload.png"
            alt=""
            className="pb-3"
          />
          {text}
        </div>

        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    );
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <div>
      <RDropzone
        maxSizeBytes={10485760}
        accept="image/jpg,image/jpeg,image/png,application/pdf,
        application/vnd.ms-excel,
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx"
        onChangeStatus={handleChangeStatus}
        autoUpload={false}
        LayoutComponent={Layout}
        InputComponent={CustomInput}
        getFilesFromEvent={getFilesFromEvent}
        initialFiles={initialFiles}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
          </div>
        )}
      </RDropzone>
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const checkBoxTermsConditions = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  value,
  meta: { error, touched },
}) => {
  return (
    <div className="form-check">
      <input
        {...input}
        type={type}
        color={"white"}
        className={"form-check-input"}
        id={id}
        placeholder={placeholder}
        value={value}
      />
      <label className="form-check-label" style={{ fontWeight: 100 }}>
        <Translate content="label.iAgree" />
        <Link
          to="/terms-and-conditions"
          target="_blank"
          style={{ fontWeight: 600 }}
        >
          <Translate content="label.terms" />
        </Link>
        <br />
        <Translate content="placeholder.privacy_policy_start" />
        <Link to="/privacy-policy" target="_blank">
          <Translate
            content="placeholder.privacy_policy"
            style={{ fontWeight: 600 }}
          />
        </Link>
        <Translate content="placeholder.privacy_policy_ending" />
        <p>
          <InputFieldError
            touched={touched}
            error={<Translate content={error} />}
          />
        </p>
      </label>
    </div>
  );
};
