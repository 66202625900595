import React, { Component } from "react";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addApplication, resetForm, changePage } from "actions/application";
import * as flashMessage from "./../../../actions/flashMessage";
import { clearLoading, clearError } from "actions/clearError";
import Loading from "components/general/Loading";
import Translate from "react-translate-component";

const pageOneFields = ["deadline", "payment_after"];
const pageTwoFields = [
  "service_id",
  "industry_ids",
  "state_id",
  "county_id",
  "description",
];
const pageThreeFields = [
  "collateral",
  "sales",
  "rating_for_credit",
  "nda_requirement",
];

class ApplyForLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: null,
    };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (action === "POP") {
        if (this.props.pageState?.page > 0) {
          this.pageBackChange();
        } else {
          this.props.history.goBack();
        }
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onPageChange = () => {
    const newEl = [...this.props.pageState?.completedPages];
    if (!newEl?.includes(this.props.pageState?.page)) {
      newEl.push(this.props.pageState.page);
    }
    this.props.history.push("?" + (this.props.pageState.page + 2));

    this.props.changePage({
      page: this.props.pageState.page + 1,
      completedPages: [...newEl],
    });
  };

  pageBackChange = () => {
    if (this.props.pageState.page > 1) {
      this.props.history.push("?" + this.props.pageState.page);
    } else {
      this.props.history.push();
    }

    this.props.changePage({
      page: this.props.pageState.page - 1,
      completedPages: this.props.pageState.completedPages,
    });
  };

  changePageFromMenu = (index) => {
    if (
      index === 0 ||
      this.props.pageState?.completedPages.includes(index - 1)
    ) {
      if (index + 1 > 1) {
        this.props.history.push("?" + (index + 1));
      } else {
        this.props.history.push();
      }

      this.props.changePage({
        page: index,
        completedPages: this.props.pageState.completedPages,
      });
    }
  };

  onFormSubmit = () => {
    const submitData = {
      ...this.props?.formValues?.PageOne?.values,
      ...this.props?.formValues?.PageTwo?.values,
      ...this.props?.formValues?.PageThree?.values,
      ...this.props?.formValues?.PageFour?.values,
    };

    const collaterals = [];
    let mergedOthers = [];
    const creditRatings = [];

    for (let i = 0; i < submitData?.collateralCount; i++) {
      let name = submitData[`collateral_${i + 1}`];
      let value = submitData[`collateral_value_${i + 1}`] || 0;
      if (name) {
        collaterals.push({ name: name, value: value });
      }
    }

    if (submitData?.service_id?.value !== 5) {
      for (let i = 0; i < submitData?.creditCount; i++) {
        let creditData = submitData[`cr_${i + 1}`];
        let value = submitData[`cr_value_${i + 1}`];

        if (creditData?.id && value) {
          creditRatings.push({ rating_id: creditData?.id, value: value });
        }
      }
    }

    const deadlineDate = new Date(submitData?.deadline);
    const deadline = `${deadlineDate.getFullYear()}-${
      deadlineDate.getMonth() + 1
    }-${deadlineDate.getDate()}`;
    const deadlineForPaymentDate = new Date(submitData?.payment_after);
    const deadlineForPayment = `${deadlineForPaymentDate.getFullYear()}-${
      deadlineForPaymentDate.getMonth() + 1
    }-${deadlineForPaymentDate.getDate()}`;

    const formData = new FormData();
    formData.set("collateral", collaterals?.length > 0 ? 1 : 0);
    formData.set("collaterals", JSON.stringify(collaterals));

    formData.set("rating_for_credit", creditRatings?.length > 0 ? 1 : 0);
    formData.set("ratings", JSON.stringify(creditRatings));

    formData.set("nda_requirement", submitData?.nda_requirement ? 1 : 0);

    if (submitData?.industry?.value)
      formData.set("industry_ids", submitData?.industry?.value);
    if (submitData?.service_id?.value)
      formData.set("service_id", submitData?.service_id?.value);
    if (submitData?.states?.value)
      formData.set("state_id", submitData?.states?.value);

    if (submitData?.county?.value)
      formData.set("county_id", submitData?.county?.value);
    formData.set("amount", submitData?.financial_need);
    if (deadline) formData.set("deadline", deadline);
    if (deadlineForPayment) formData.set("payment_after", deadlineForPayment);
    if (submitData?.time_duration)
      formData.set("time_duration", submitData?.time_duration);
    formData.set("sales", submitData?.sale_amount);

    formData.set("profit_after_tax", submitData?.profit_after_tax);

    if (submitData?.description)
      formData.set("description", submitData?.description);

    if (submitData.balance_sheet) {
      submitData.balance_sheet.map((file, index) =>
        formData.append(`file_balance_sheet[${index}]`, file)
      );
    }
    if (submitData.latest_bwa) {
      submitData.latest_bwa.map((file, index) =>
        formData.append(`file_latest_bwa[${index}]`, file)
      );
    }
    if (submitData.trade_register_excerpt) {
      submitData.trade_register_excerpt.map((file, index) =>
        formData.append(`file_trade_register_excerpt[${index}]`, file)
      );
    }
    if (submitData.list_of_shareholders) {
      submitData.list_of_shareholders.map((file, index) =>
        formData.append(`file_list_of_shareholders[${index}]`, file)
      );
    }
    if (submitData.bylaws_articles) {
      submitData.bylaws_articles.map((file, index) =>
        formData.append(`file_bylaws_articles_of_incorporation[${index}]`, file)
      );
    }
    if (submitData.business_plan) {
      submitData.business_plan.map((file, index) =>
        formData.append(`file_business_plan[${index}]`, file)
      );
    }
    if (submitData.financial_plan) {
      submitData.financial_plan.map((file, index) =>
        formData.append(`file_financial_plan[${index}]`, file)
      );
    }
    if (submitData.information_about_owner) {
      submitData.information_about_owner.map((file, index) =>
        formData.append(`file_information_about_owner[${index}]`, file)
      );
    }

    if (submitData?.confidential_statement) {
      mergedOthers = [...mergedOthers, ...submitData?.confidential_statement];
    }
    if (submitData?.other_documents) {
      mergedOthers = [...mergedOthers, ...submitData?.other_documents];
    }

    if (mergedOthers?.length > 0) {
      mergedOthers.forEach((file, index) =>
        formData.append(`file_other_documents[${index}]`, file)
      );
    }

    this.props.addApplication(formData, () => {
      this.props.resetForm();

      this.props.changePage({
        page: 0,
        completedPages: [],
      });

      this.props.history.push("/list-application");
      this.props.showFlashMessage("alert.application_add");
      setTimeout(() => {
        this.props.hideFlashMessage();
      }, 5000);
    });
  };
  componentDidMount() {
    this.props.clearError();
    this.props.clearLoading();
  }

  componentDidUpdate(prevProps) {
    if (this.props.errFields && prevProps?.errFields !== this.props.errFields) {
      // const first = this.props.errFields[0]

      if (this.props.errFields.some((el) => pageOneFields.includes(el))) {
        this.setState({
          page: 0,
        });
      } else if (
        this.props.errFields.some((el) => pageTwoFields.includes(el))
      ) {
        this.setState({
          page: 1,
        });
      } else if (
        this.props.errFields.some((el) => pageThreeFields.includes(el))
      ) {
        this.setState({
          page: 2,
        });
      }
    }
  }

  render() {
    const pageClass = (index) => {
      if (this.props.pageState?.page === index) {
        return "doing";
      }
      if (this.props.pageState?.page > index) {
        return "completed";
      }
      return "to-do";
    };

    const pageLinkList = [
      {
        component: <PageOne onPageChange={this.onPageChange} />,
        label: <Translate content="steps.step1" />,
      },
      {
        component: (
          <PageTwo
            onPageChange={this.onPageChange}
            pageBackChange={this.pageBackChange}
          />
        ),
        label: <Translate content="steps.step2" />,
      },
      {
        component: (
          <PageThree
            onPageChange={this.onPageChange}
            pageBackChange={this.pageBackChange}
          />
        ),
        label: <Translate content="steps.step3" />,
      },
      {
        component: (
          <PageFour
            onFormSubmit={this.onFormSubmit}
            pageBackChange={this.pageBackChange}
          />
        ),
        label: <Translate content="steps.step4" />,
      },
    ];
    return (
      <>
        <div className="Breadcrumb mt-4 pb-4">
          <h4>{<Translate content="label.application" />}</h4>
        </div>
        <div
          className="multiStepPagination mt-4 mb-4"
          style={{ display: "flex" }}
        >
          <ol
            className="w-100 pl-0 justify-content-center"
            style={{
              listStyle: "none",
              display: "flex",
            }}
          >
            {pageLinkList.map(({ label }, index) => {
              return (
                <li
                  className={`w-25 stepsPagination ` + pageClass(index)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  key={index}
                  onClick={() => this.changePageFromMenu(index)}
                >
                  <span className="stepsBar"></span>
                  <span>{label}</span>
                </li>
              );
            })}
          </ol>
        </div>
        <Loading />
        {pageLinkList.map(({ component }, index) => {
          if (this.props.pageState?.page === index) {
            return <div key={index}>{component}</div>;
          }
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageState: state.addApplicationPageState,
    formValues: state.form,
    errMsg: state.errors,
    errFields: state.error_fields,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    ...flashMessage,
    addApplication,
    resetForm,
    clearLoading,
    clearError,
    changePage,
  })(ApplyForLoan)
);
