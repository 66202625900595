import React, { Component } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

export default (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }

    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      // give false condition
      if (this.props.auth) {
        const { exp } = jwt_decode(this.props.auth);
        if (exp * 1000 > new Date().getTime()) {
          this.props.history.push("/dashboard");
        } else {
          localStorage.removeItem("token");
        }
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      auth: state.auth.authenticated,
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
};
