import React from "react";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";

export default (props) => {
  return (
    <div class="content m-auto">
      <div class="email-content text-center w-75 m-auto">
        {" "}
        <img src="./assets/img/icons/not-verified.svg" alt="Mail icon" />
        {/* <h3>Account not verified</h3> */}
        <Translate content="label.accountNotVerified" component="h3" />
        {/* <p></p> */}
        <Translate content="unverified.msg" component="p" />
        {/* <Link class="btn btn-primary mr-3" href="contact-us.html"><Translate content='label.contact' /></Link> */}
        <Link class="btn btn-primary" to="/user/profile">
          <Translate content="button.profile" />
        </Link>
      </div>
    </div>
  );
};
