import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getNotificationCount } from "../../actions/notification";
import { getProfile } from "../../actions/profile";
import { changeLanguage } from "../../actions/changeLanguage";
import { logout } from "../../actions/login";
import en from "../../_locale/en";
import de from "../../_locale/de";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import Loading from "./Loading";
import { SidebarContext } from "./SidebarContext";

counterpart.registerTranslations("en", en);
counterpart.registerTranslations("de", de);
counterpart.setLocale(
  "de" || localStorage.getItem("language") || navigator.language.split("-")[0]
);

class Header extends Component {
  componentDidMount() {
    this.props.getNotificationCount();
    this.props.getProfile();
  }

  onChange = (e) => {
    // let language = e.target.value;
    counterpart.setLocale(e);
    this.setState({ language: e });
    this.props.changeLanguage(e);
  };

  static contextType = SidebarContext;

  render() {
    if (!this.props.profile) {
      return <Loading />;
    } else {
      const { company_logo_link } = this.props.profile;

      return (
        <header className="site-header">
          <div className="logo-container">
            <div id="hamburger" onClick={this.context.toggleSidebar}>
              <div className="hamburger-bar"></div>
            </div>
            <Link to="/Dashboard">
              <img src="/assets/img/logo.png" alt="Pi-hub Logo" />
            </Link>
          </div>
          <nav className="header-actions">
            <ul>
              <ul className="language-changer">
                <li>
                  <button onClick={() => this.onChange("en")}>
                    <img src="/assets/img/gb.svg" alt="English Language" />
                    English
                  </button>
                </li>
                <li>
                  <button onClick={() => this.onChange("de")}>
                    <img src="/assets/img/de.svg" alt="Deutsch Language" />
                    Deutsch
                  </button>
                </li>
              </ul>
              <li className="header-actions__item">
                <Link className="header-notification" to="/notifications">
                  <i className="bx bx-bell" />
                  <span className="notification-count">{this.props.count}</span>
                </Link>
              </li>
              <div className="dropdown">
                <button
                  to=""
                  className=" dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="dropdown-user"
                    src={
                      company_logo_link
                        ? company_logo_link
                        : "/assets/img/user.png"
                    }
                    alt="John Doe"
                  />
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link className="dropdown-item" to="/user/profile">
                    <Translate content="button.profile" />
                  </Link>
                  <Link className="dropdown-item" to="/user/edit-profile">
                    <Translate content="button.editProfile" />
                  </Link>
                  <Link className="dropdown-item" to="/change-password">
                    <Translate content="button.reset" />
                  </Link>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      this.props.logout(() => this.props.history.push("/"));
                    }}
                  >
                    <Translate content="button.logout" />
                  </span>
                </div>
              </div>
            </ul>
          </nav>
        </header>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    count: state.notificationCount,
    language: state.language,
    profile: state.profile,
  };
}

export default connect(mapStateToProps, {
  getNotificationCount,
  getProfile,
  logout,
  changeLanguage,
})(withRouter(Header));
