import React, {Component, Fragment} from "react";
import Subheader from "../general/Subheader";
import Translate from "react-translate-component";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {fetchApplications} from "actions/application";
import Moment from "react-moment";
import {applicationDebtorStatus} from "../../_status";
import {ToEuro} from "../general/CurrencyFormatter";
import Pagination from "../general/Pagination";
import Loading from "components/general/Loading";

class ListApplication extends Component {
    componentDidMount() {
        this.props.fetchApplications();
    }

    renderList = applicationList => {
        return (<table
            className="table tablesaw-stack table--last-right"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
        >
            <thead>
            <tr>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                    <Translate content="label.service"/>
                </th>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                    <Translate content="label.duration"/>
                </th>
                <th
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    scope="col"
                >
                    <Translate content="label.deadline"/>
                </th>
                <th
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    scope="col"
                >
                    <Translate content="label.appliedOn"/>
                </th>
                <th
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    scope="col"
                >
                    <Translate content="label.matches"/>
                </th>
                <th
                    className="text-right-piehub-table"
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    scope="col"
                >
                    <Translate content="label.appliedAmount"/>
                </th>
                <th
                    className="text-right-piehub-table"
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    scope="col"
                >
                    <Translate content="label.status"/>
                </th>
            </tr>
            </thead>
            <tbody>
            {((applicationList.data === undefined || applicationList.data.length === 0) ? (
                    <tr>
                        <td>
                            <Translate content="label.noApplication"/>
                        </td>
                    </tr>
                ) : applicationList.data.map((application) => (<tr key={application.id}>
                    <td className="table-spacing-3">
                        {/* @todo enable this and fix editing applications */}
                        <Link to={{pathname: `/edit-application/${application.id}`}}>
                            {application.service.name[this.props.language]}
                        </Link>
                    </td>
                    <td>{application.time_duration}</td>
                    <td>
                        <Moment format="DD.MM.YYYY">{application.deadline}</Moment>
                    </td>
                    <td>
                        <Moment format="DD.MM.YYYY">{application.applied_on}</Moment>
                    </td>
                    <td>
                        <Link to={{pathname: `/match-application/${application.id}`}}>
                            <strong>{application.matches}</strong>
                        </Link>
                    </td>
                    <td className="text-right-piehub-table">
                        <strong>
                            <ToEuro amount={application.applied_amount}/>
                        </strong>
                    </td>
                    <td className="text-right-piehub-table">
              <span
                  className={`badge ${
                      applicationDebtorStatus[application.status].class
                  }`}
              >
                <Translate
                    content={
                        applicationDebtorStatus[application.status].translation_key
                    }
                />
              </span>
                    </td>
                </tr>))
            )}
            </tbody>
        </table>);
    };

    render() {
        const {totalPage} = this.props.pagination;
        return (
            <Fragment>
                <Subheader heading={<Translate content="sidebar.my_application"/>}/>
                <div className="content-body">
                    <Loading/>
                    {this.props.ListApplications
                        ? this.renderList(this.props.ListApplications)
                        : null}
                    <Pagination
                        totalPage={totalPage}
                        url={page => this.props.fetchApplications(page)}
                    />
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        applicationDetails: state.applicationDetail.applicationDetails,
        ListApplications: state.applicationDetail.applicationList,
        pagination: state.pagination,
        errMsg: state.errors,
        language:state.language
    };
}

export default connect(mapStateToProps, {
    fetchApplications
})(ListApplication);
