import client from "./index";
import { routes } from "_api/routes";
import { FETCH_STATES, FETCH_COUNTIES, ERROR } from "actions/types";
import { extractServerErrors } from "../_utils/validate";

export const fetchStates = () => async dispatch => {
  try {
    const response = await client.get(`${routes.states}`);

    dispatch({
      type: FETCH_STATES,
      payload: response.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};

export const fetchCounties = stateId => async dispatch => {
  try {
    const response = await client.get(`${routes.states}/${stateId}/counties`);

    dispatch({
      type: FETCH_COUNTIES,
      payload: response.data
    });
  } catch (e) {
    if (e.response.data.message) {
      dispatch({
        type: ERROR,
        payload: e.response.data.message
      });
    } else {
      dispatch({
        type: ERROR,
        payload: "Unable to list states now"
      });
    }
  }
};
