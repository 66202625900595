import { ADD_APPLICATION_PAGE_STATE } from "../actions/types";

export default function (state = { page: 0, completedPages: [] }, action) {
  switch (action.type) {
    case ADD_APPLICATION_PAGE_STATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
