import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { fetchApplications } from "actions/application";
import { getProducts } from "../../actions/products";
import { ToEuro } from "../general/CurrencyFormatter";
import Moment from "react-moment";
import Loading from "components/general/Loading";
import { downloadFile } from "../../actions/download";

class View extends Component {
  state = { detail: null };

  componentDidMount() {
    const { product_id } = this.props.location.state;

    this.props.getProducts(product_id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.detail !== this.props.detail) {
      this.setState({ detail: this.props.detail.detail.data });
    }
  }

  showGeoLocation = locations => {
    if (locations.length === 0) {
      return <div className="product__info"> Not available</div>;
    } else {
      return locations.map((location, index) => {
        return (
          <div className="product__info mb-1" key={index}>
            {" "}
            {location.name}
          </div>
        );
      });
    }
  };

  renderIndustries = industries => {
    if (industries.length === 0) {
      return <div className="product__info"> Not available</div>;
    } else {
      return industries.map((industry, index) => {
        return (
          <div className="product__info mb-1" key={index}>
            {industry.name ? industry.name[this.props.language] : ""}
          </div>
        );
      });
    }
  };

  showRating = ratings => {
    if (ratings.length === 0) {
      return <p className="product__info">Not available</p>;
    } else {
      return ratings.map((rating, index) => {
        return (
          <div className="col">
            <h6 className="product__title">{rating.name}</h6>
            <p className="product__info">{rating.value}</p>
          </div>
        );
      });
    }
  };
  showAttachments = documents => {
    if (documents.length === 0) {
      return <span>No Attachments Available</span>;
    } else {
      return documents.map((doc, index) => {
        return (
          <div className="file mb-2" key={index}>
            <span className="file-name">
              File {index + 1}
              <button
                className="btn btn-link"
                onClick={event => {
                  event.preventDefault();
                  this.props.downloadFile(
                    {
                      file_list: [
                        {
                          file_name: doc.file_name,
                          file_path: doc.path
                        }
                      ]
                    },
                    doc.file_name
                  );
                }}
              >
                Download
              </button>
            </span>
            <span className="ml-4 file-size">Type: {doc.type}</span>
          </div>
        );
      });
    }
  };

  render() {
    if (this.state.detail) {
      const {
        status,
        states,
        documents,
        service,
        counties,
        collateral,
        collaterals,
        min_sales_creditor,
        industries,
        investor,
        ratings,
        min_time_duration,
        max_time_duration,
        product_title,
        min_credit_amount,
        max_credit_amount
      } = this.state.detail;

      return (
        <Fragment>
          <div className="content-head row">
            <div className="content-head-left col-md-4">
              <h1 className="content-head__title">
                Offer for {product_title}{" "}
              </h1>
            </div>
            <div className="content-head-right col-md-8"></div>
          </div>
          <div className="content-body">
            <Loading />
            <div className="product-details row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                <div className="row">
                  <div className="col-4 product-info">
                    <h6 className="product__title">Investor</h6>
                    <p className="product__info">{investor.full_name}</p>
                  </div>
                  <div className="col-4 product-info">
                    <h6 className="product__title">states</h6>
                    {this.showGeoLocation(states)}
                  </div>
                  <div className="col-4 product-info">
                    <h6 className="product__title">Service</h6>
                    <p className="product__info">
                      {service.name ? service.name[this.props.language] : ""}
                    </p>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-info">
                    <h6 className="product__title">County</h6>
                    {/* <p className="product__info">{}</p> */}
                    <div className="product-info-detail">
                      {this.showGeoLocation(counties)}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-info">
                    <h6 className="product__title">Industry</h6>
                    {/* <p className="product__info"> */}
                    {/* {industries} */}
                    {/* </p> */}
                    <div className="product-info-detail">
                      {this.renderIndustries(industries)}
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-4 product-info">
                    <h6 className="product__title">Status</h6>
                    <p className="product__info">{status}</p>
                  </div>

                  <div className="col-4 product-info">
                    <h6 className="product__title">collateral</h6>
                    {collateral === "1" ? (
                      <p className="product__info">Yes</p>
                    ) : (
                      <p className="product__info">No</p>
                    )}
                  </div>
                  {collateral === "1" ? this.showRating(collaterals) : null}
                </div>
                <div className="row mt-2">
                  <div className="col product-info">
                    <h6 className="product__title">Rating for Credit</h6>
                    {this.showRating(ratings)}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <h6 className="product__title">Attachments</h6>
                    {documents ? this.showAttachments(documents) : null}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-details-right">
                <div className="row">
                  <div className="col-12">
                    <h6 className="product__title">Maximum credit Amount</h6>
                    <h2 className="product__info">
                      <ToEuro amount={max_credit_amount} />
                    </h2>
                  </div>
                  <div className="col-12">
                    <h6 className="product__title">Minimum credit Amount</h6>
                    <h2 className="product__info">
                      <ToEuro amount={min_credit_amount} />
                    </h2>
                  </div>
                  <div className="col-12">
                    <h6 className="product__title">min sales creditor</h6>
                    <p className="product__info">
                      <ToEuro amount={min_sales_creditor} />
                    </p>
                  </div>
                  <div className="col-12">
                    <h6 className="product__title">Minimum Time Duration</h6>
                    <p className="product__info">
                      <Moment format="DD.MM.YYYY">{min_time_duration}</Moment>
                    </p>
                  </div>
                  <div className="col-12">
                    <h6 className="product__title">Maximum Time Duration</h6>
                    <p className="product__info">
                      <Moment format="DD.MM.YYYY">{max_time_duration}</Moment>
                    </p>
                  </div>
                </div>
              </div>
              <br />
              {this.props.errorMsg ? (
                <font color="red">{this.props.errorMsg}</font>
              ) : null}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps(state) {
  return {
    detail: state.products,
    errorMsg: state.error,
    ListApplications: state.applicationDetail.applicationList,
    language:state.language
  };
}

export default connect(mapStateToProps, {
  getProducts,
  fetchApplications,
  downloadFile
})(View);
