import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { inputField } from "../../_formFields";
import { getTokenForEmail } from "../../actions/password";
import Translate from "react-translate-component";
import LanguageFlags from "../general/LanguageFlags";
import { displayServerErrors } from "../../_utils/validate";

class ForgotPassword extends Component {
  state = { submit: false, email: null };
  onSubmit = formProps => {
    this.props.getTokenForEmail(formProps, () => {
      this.setState({ submit: true, email: formProps.email });
    });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Fragment>
        <nav className="header-actions">
          <img
            className="company-logo company-logo-email"
            src="/assets/img/logo.png"
            alt="company logo"
          />
          <LanguageFlags />
        </nav>
        <div className="container-full-height text-centerd d-flex">
          <div className="content m-auto">
            {this.state.submit ? (
              <div className="email-content text-center w-75 m-auto">
                <img src="/assets/img/icons/Mail.png" alt="Mail icon" />
                <Translate content="label.weJustSent" component="h3" />
                <p>
                  <Translate content="label.anEmailWithInstruction" />{" "}
                  <b>{this.state.email}</b>
                </p>
              </div>
            ) : (
              <div className="email-content">
                <div className="w-75 m-auto text-center">
                  <img src="/assets/img/icons/activated.png" alt="Mail icon" />
                  <h3>
                    <Translate content="label.forgotPassword" />
                  </h3>
                  <p>
                    <Translate content="label.enterEmailForgotten" />
                  </p>
                </div>
                <div className="w-75 m-auto">
                  <form
                    className="form-signin"
                    onSubmit={handleSubmit(this.onSubmit)}
                  >
                    <div className="form-group text-left w-75">
                      <Field
                        type="email"
                        name="email"
                        component={inputField}
                        className="form-control"
                        placeholder="label.email_address"
                      />
                    </div>
                    {this.props.errMsg
                      ? displayServerErrors(this.props.errMsg)
                      : null}
                    <button className="btn btn-primary btn-form" type="submit">
                      <Translate content="button.submit" />
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { errMsg: state.errors };
}

export default compose(
  connect(mapStateToProps, { getTokenForEmail }),
  reduxForm({
    form: "forgotPassword"
  })
)(ForgotPassword);
