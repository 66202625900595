import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { inputField } from "../../_formFields";
import * as validation from "../../_utils/validate";
import * as actions from "../../actions/login";
import * as flashMessage from "../../actions/flashMessage";
import Translate from "react-translate-component";
import Loading from "../general/Loading";
import LanguageFlags from "../general/LanguageFlags";

class Login extends Component {
  onSubmit = formProps => {
    let redirect = localStorage.getItem("redirect");
    this.props.signin(formProps, firstTimeLogin => {
      if (firstTimeLogin === true) {
        this.props.history.push("/signup/activated");

        return;
      }
      if (redirect != null) {
        this.props.history.push(redirect);
        localStorage.removeItem("redirect");
      } else {
        this.props.history.push("/");
      }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container-fluid container-full-height">
        <div className="row container-full-height">
          <div className="signin-form-container col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="signin-container">
              <div className="logo-container">
                <nav className="header-actions">
                  <a href=" https://www.pihub-pi.com/de/" target="_blank">
                    <img src="/assets/img/logo.png" alt="Pinhub Logo" />
                  </a>
                  <LanguageFlags />
                </nav>
              </div>
              <Loading />
              <header className="page-header">
                <Translate
                  content="button.login"
                  component="h1"
                  className="page-title"
                />

                <Translate
                  content="text_blocks.detailsBelow"
                  component="p"
                  className="page-desc"
                />
              </header>
              <form
                className="form-signin"
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <div className="form-group">
                  <Field
                    name="email"
                    type="text"
                    component={inputField}
                    label={<Translate content="label.email_address" />}
                    className="form-control"
                    validate={[validation.newEmail, validation.required]}
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="password"
                    type="password"
                    component={inputField}
                    label={<Translate content="label.password" />}
                    className="form-control"
                    validate={validation.required}
                  />
                </div>

                {this.props.errorMessage ? (
                  <div className="d-flex mb-1">
                    <i className="bx bx-x-circle icon-danger mb-2">
                      <font className="error-font pl-2 green-text">
                        {this.props.errorMessage}
                      </font>
                    </i>
                  </div>
                ) : null}
                <Link to="/forgot-password">
                  {" "}
                  <Translate content="label.forgotPassword" />{" "}
                </Link>
                <br />
                {
                  <Translate
                    content="button.login"
                    component="button"
                    className="btn btn-primary btn-form"
                    type="submit"
                  />
                }
              </form>
              <br />
              <Translate content="text_blocks.noAccount" />
              &nbsp;
              <Link to="/signup">
                <strong>
                  <Translate content="text_blocks.here" />
                </strong>
              </Link>
            </div>
          </div>
          <div className="signin-banner-container col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="signin-banner">
              <header className="signin-banner__header">
                <Translate
                  content="text_blocks.loginTitle"
                  component="h2"
                  className="signin-banner__title"
                />
                <Translate
                  content="text_blocks.loginDescription"
                  component="p"
                  className="signin-banner__desc"
                />
              </header>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage
  };
}

export default compose(
  connect(mapStateToProps, { ...actions, ...flashMessage }),
  reduxForm({
    form: "login"
  })
)(Login);
