import {
  ADD_APPLICATION,
  LIST_APPLICATION,
  EDIT_APPLICATION,
  FETCH_MATCH_APPLICATION
} from "actions/types";

const initialState = {
  applicationDetails: {},
  applicationList: [],
  application: {},
  matchApplicationList: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_APPLICATION:
      return {
        ...state,
        applicationDetails: action.payload
      };
    case EDIT_APPLICATION:
      return {
        ...state,
        applicationDetails: action.payload
      };

    case LIST_APPLICATION:
      return {
        ...state,
        applicationList: action.payload
      };

    case FETCH_MATCH_APPLICATION:
      return {
        ...state,
        matchApplicationList: action.payload
      };

    default:
      return state;
  }
}
