import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PageFourForm, { filesList } from "../forms/PageFourForm";

class PageFourEdit extends Component {
  render() {
    return <PageFourForm {...this.props} pageEdit />;
  }
}

PageFourEdit = reduxForm({
  form: "PageFourEdit",
  destroyOnUnmount: false,
  validate,
})(PageFourEdit);

function validate(values) {
  const error = {};
  const check = filesList.every((e) =>
    Boolean(
      values[e] || values?.applicationFiles?.find((file) => file.type === e)
    )
  );
  if (!check) error.showInfoErrorMessage = true;
  return error;
}

const selector = formValueSelector("PageFourEdit");

const mapStateToProps = (state) => {
  const applicationFiles = selector(state, "applicationFiles");
  const initialFiles = { ...state.form.PageFourEdit };

  const serviceAlternate =
    state.form.PageTwoEdit?.values.service_id?.value === 5;

  const applicationFilesArr = [
    ...state?.fetchApplication?.application?.all_files,
  ];

  return {
    initialFiles,
    serviceAlternate,
    applicationFiles,
    errMsg: state.errors,
    applicationDetails: state?.fetchApplication?.application,
    initialValues: {
      applicationFiles: applicationFilesArr,
    },
  };
};

export default connect(mapStateToProps, {})(PageFourEdit);
