import { OFFER_RECEIVED } from "../actions/types";

export default function(state = null, action) {
  switch (action.type) {
    case OFFER_RECEIVED:
      return { ...state, detail: action.payload };
    default:
      return state;
  }
}
