import React, { Component, Fragment } from "react";
import Subheader from "../general/Subheader";
import Translate from "react-translate-component";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../general/Pagination";
import { fetchMatchApplications } from "actions/application";
import Moment from "react-moment";
import { ToEuro } from "../general/CurrencyFormatter";
import { matchesDebtorStatus } from "../../_status";
import Loading from "components/general/Loading";

class MatchApplication extends Component {
  componentDidMount() {
    this.props.fetchMatchApplications(this.props.match.params.application_id);
  }

  renderList = applicationList => {
    // @todo states should be designed so that it doesn't grow too long in the list.
    if (applicationList !== "undefined" && applicationList.length > 0) {
      let data = [...applicationList];
      return data.map((application, index) => {
        return (
          <tr>
            <td>{application.investor_category}</td>
            <td>{application.states ? application.states.join("\n") : ""}</td>
            <td>
              <ToEuro amount={application.min_credit_amount} />
            </td>
            <td>
              <ToEuro amount={application.max_credit_amount} />
            </td>
            <td>
              <Moment format="DD.MM.YYYY">{application.created_on}</Moment>
            </td>
            <td className="text-left text-md-right">
              <Moment format="DD.MM.YYYY">{application.expires_on}</Moment>
            </td>
            <td className="text-left text-md-right">
              <span
                className={`badge ${
                  matchesDebtorStatus[application.status].class
                }`}
              >
                <Link
                  to={
                    application.status === "offer_received"
                      ? {
                          pathname: "/offer_received",
                          state: {
                            product_id: application.product_id,
                            application_id: application.application_id
                          }
                        }
                      : {}
                  }
                  style={{ color: "white", fontWeight: 500 }}
                >
                  <Translate
                    content={
                      matchesDebtorStatus[application.status].translation_key
                    }
                  />
                </Link>
              </span>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>
            <Translate content="text_blocks.noData" />
          </td>
        </tr>
      );
    }
  };

  render() {
    const { totalPage } = this.props.pagination;
    const { matchApplications } = this.props;

    if (typeof matchApplications == "undefined") {
      return <Loading />;
    }
    return (
      <Fragment>
        <Subheader heading={<Translate content="label.matchedProducts" />} />
        <div className="content-body">
          <Loading />
          <table
            className="table tablesaw-stack table--last-right"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="label.anonymizedInvestor" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="label.states" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.minimum_credit_amount" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.maximum_credit_amount" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="label.createdOn" />
                </th>
                <th
                  className="text-left text-md-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="label.expiresOn" />
                </th>
                <th
                  className="text-left text-md-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="label.status" />
                </th>
              </tr>
            </thead>

            <tbody>{this.renderList(matchApplications)}</tbody>
          </table>
          <Pagination
            totalPage={totalPage}
            url={page => this.props.fetchApplications(page)}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    matchApplications: state.applicationDetail.matchApplicationList.data,
    pagination: state.pagination
  };
}

export default connect(mapStateToProps, {
  fetchMatchApplications
})(MatchApplication);
