export const AUTH_USER = "auth_user";
export const AUTH_ERROR = "auth_error";
export const USER_DETAIL = "user_detail";
export const PRODUCTS_LIST = "products_list";
export const PRODUCTS_LIST_ERROR = "products_list_error";
export const ERROR = "error";
export const CLEAR_ERROR = "clear_error";
export const ERROR_FIELDS = "error_fields";
export const CLEAR_ERROR_FIELDS = "clear_error_fields";
export const CREDIT_REQUESTS_LIST = "credit_requests_list";
export const LIST_NOTIFICATION = "list_notification";
export const NOTIFICATION_COUNT = "notification_count";
export const SINGLE_PRODUCT = "single_product";
export const PAGINATION = "PAGINATION";
export const FETCH_INDUSTRIES = "FETCH_INDUSTRIES";
export const ADD_CORPORATE_INFORMATION = "ADD_CORPORATE_INFORMATION";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const EDIT_APPLICATION = "EDIT_APPLICATION";
export const LIST_APPLICATION = "LIST_APPLICATION";
export const FETCH_APPLICATION = "FETCH_APPLICATION";
export const FETCH_MATCH_APPLICATION = "FETCH_MATCH_APPLICATION";
export const FETCH_STATES = "FETCH_STATES";
export const FETCH_CREDIT = "FETCH_CREDIT";
export const FETCH_COUNTIES = "FETCH_COUNTIES";
export const FETCH_RATINGS = "FETCH_RATINGS";
export const CHANGE_LANGUAGE = "change_language";
export const FETCH_SERVICES = "fetch_services";
export const GET_PROFILE = "get_profile";
export const PRODUCTS = "products";
export const OFFER_RECEIVED = "offer_received";
export const SCOPE = "scope";
export const SHOW_FLASH_MESSAGE = "show_flash_message";
export const HIDE_FLASH_MESSAGE = "hide_flash_message";
export const LOADING = "loading";
export const LOADING_DONE = "loading_done";
export const CLEAR_LOADING = "clear_loading";

export const CLEAR_PAGEONE_FORM = "CLEAR_PAGEONE_FORM";
export const CLEAR_PAGETWO_FORM = "CLEAR_PAGETWO_FORM";
export const CLEAR_PAGETHREE_FORM = "CLEAR_PAGETHREE_FORM";
export const CLEAR_PAGEFOUR_FORM = "CLEAR_PAGEFOUR_FORM";

export const CLEAR_PAGEONEEDIT_FORM = "CLEAR_PAGEONEEDIT_FORM";
export const CLEAR_PAGETWOEDIT_FORM = "CLEAR_PAGETWOEDIT_FORM";
export const CLEAR_PAGETHREEEDIT_FORM = "CLEAR_PAGETHREEEDIT_FORM";
export const CLEAR_PAGEFOUREDIT_FORM = "CLEAR_PAGEFOUREDIT_FORM";

export const ADD_APPLICATION_PAGE_STATE = "ADD_APPLICATION_PAGE_STATE";
