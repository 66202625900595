import axios from "axios";
import jwt_decode from "jwt-decode";

let client = axios;
axios.interceptors.request.use(
  async (config) => {
    //if token in localstorage and has not expired add to all axios call

    if (localStorage.getItem("token")) {
      const { exp } = jwt_decode(localStorage.getItem("token"));
      if (exp * 1000 > Date.now()) {
        config.headers.Authorization =
          `Bearer ` + localStorage.getItem("token");
      }
    }
    config.headers.From = process.env.REACT_APP_API_HEADER_FROM;
    config.headers["Content-Type"] = "application/json";
    config.headers.Locale = localStorage.getItem("language") || "de";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = window.location.origin + "/";
    }

    return Promise.reject(error);
  }
);

export default client;
