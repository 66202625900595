import client from "./index";
import { routes } from "./../_api/routes";
import { AUTH_ERROR, AUTH_USER, SCOPE, LOADING, LOADING_DONE } from "./types";
import jwt_decode from "jwt-decode";
import { extractServerErrors } from "../_utils/validate";

const { BroadcastChannel } = require("broadcast-channel");
const logoutChannel = new BroadcastChannel("");
export const signin =
  ({ email, password }, callback) =>
  async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.login}`,
        method: "post",
        data: { email, password },
      },
    });
    try {
      const response = await client.post(`${routes.login}`, {
        email,
        password,
      });
      const { scopes } = jwt_decode(response.data.message.token);
      const firstTimeLogin = response.data.message.first_time_login;
      let verified;

      if (scopes.indexOf("unconfirmed_scope") >= 0) {
        verified = false;
      } else {
        verified = true;
      }

      localStorage.setItem("token", response.data.message.token);
      sessionStorage.setItem(
        "profileComplete",
        response.data.message.profile_complete
      );
      dispatch({
        type: SCOPE,
        payload: verified,
      });

      dispatch({
        type: AUTH_USER,
        payload: response.data.message.token,
      });
      callback(firstTimeLogin);
    } catch (e) {
      if (e.isAxiosError) {
        dispatch({
          type: AUTH_ERROR,
          payload:
            typeof e.response === "undefined"
              ? e.message
              : extractServerErrors(e.response.data),
        });
      }
    } finally {
      dispatch({
        type: LOADING_DONE,
        payload: {
          URL: `${routes.login}`,
          method: "post",
          data: { email, password },
        },
      });
    }
  };

export const logout = (callback) => async (dispatch) => {
  try {
    logoutChannel.postMessage({
      userId: "",
      payload: {
        type: "LOGOUT",
      },
    });

    const response = await client.get(routes.logout);

    dispatch({
      type: AUTH_USER,
      payload: undefined,
    });
    if (response) {
      localStorage.removeItem("token");
      callback();
    }
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: AUTH_ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data),
      });
    }

    dispatch({
      type: AUTH_USER,
      payload: undefined,
    });
    localStorage.removeItem("token");
    callback();
  }
};

export const logoutAllTabsEventListner = () => async (dispatch) => {
  logoutChannel.onmessage = (data) => {
    if (data.payload.type === "LOGOUT") {
      localStorage.removeItem("token");
      window.location.href = window.location.origin + "/login";
    }
    logoutChannel.close();
  };
};
