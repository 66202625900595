import React, {Fragment, Component} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {compose} from "redux";
import {inputField} from "../../../_formFields";
import {
    getTokenForEmail,
    changePasswordWithToken
} from "../../../actions/password";
import * as validation from "../../../_utils/validate";
import Translate from "react-translate-component";
import {Link} from "react-router-dom";
import LanguageFlags from "../../general/LanguageFlags";

class ChangePassword extends Component {
    state = {submit: false, token: null};
    displayErrors = errors => {
        return errors.map((err, index) => {
            return (
                <li className="d-flex mb-1" key={index}>
                    <i className="bx bx-x-circle icon-danger mb-2">
                        <font className="pl-2 green-text error-font">
                            {err}
                        </font>{" "}
                    </i>
                </li>
            );
        });
    };
    onSubmit = formProps => {
        if (!this.state.submit) {
            this.props.getTokenForEmail(formProps, token => {
                this.setState({submit: true, token: token});
            });
        } else {
            formProps.token = this.state.token;
            this.props.changePasswordWithToken(formProps, () => {
                this.props.history.push("/password-change-success");
            });
        }
    };

    render() {
        const {handleSubmit} = this.props;
        if (!this.state.submit) {
            return (
                <Fragment>
                    <nav className="header-actions">
                        <Link to="/signup">
                            {" "}
                            <img
                                className="company-logo company-logo-email"
                                src="/assets/img/logo.png"
                                alt="company logo"
                            />
                        </Link>
                        <LanguageFlags/>
                    </nav>
                    <div className="container-full-height text-centerd d-flex">
                        <div className="content m-auto">
                            <div className="email-content">
                                <div className="w-75 m-auto text-center">
                                    <img src="./assets/img/icons/activated.png" alt="Mail icon"/>
                                    <Translate content="label.changePassword" component="h3"/>
                                    <Translate
                                        content="label.enterAnEmailAssociated"
                                        component="p"
                                    />
                                </div>
                                <div className="w-75 m-auto">
                                    <form
                                        className="form-signin"
                                        onSubmit={handleSubmit(this.onSubmit)}
                                    >
                                        <div className="form-group text-left w-75">
                                            <Field
                                                type="email"
                                                name="email"
                                                component={inputField}
                                                className="form-control"
                                                placeholder="label.email_address"
                                            />
                                        </div>
                                        {this.props.errMsg
                                            ? this.displayErrors(this.props.errMsg)
                                            : null}
                                        <button className="btn btn-primary btn-form" type="submit">
                                            <Translate content="button.submit"/>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <img
                        className="company-logo company-logo-email"
                        src="./assets/img/logo.png"
                        alt="company logo"
                    />
                    <div className="container-full-height text-centerd d-flex">
                        <div className="content m-auto">
                            <div className="email-content">
                                <div className="w-75 m-auto text-center">
                                    <img src="./assets/img/icons/activated.png" alt="Mail icon"/>
                                    <Translate content="label.resetYourPassword" component="h3"/>
                                </div>
                                <div className="w-75 m-auto">
                                    <form
                                        className="form-signin"
                                        onSubmit={handleSubmit(this.onSubmit)}
                                    >
                                        <div className="form-group text-left w-75">
                                            <Field
                                                type="password"
                                                name="password"
                                                component={inputField}
                                                className="form-control"
                                                placeholder="placeholder.NewPassword"
                                            />
                                        </div>
                                        {" "}
                                        <div className="form-group text-left w-75">
                                            <Field
                                                type="password"
                                                name="password_confirmation"
                                                component={inputField}
                                                className="form-control"
                                                placeholder="placeholder.Retypepassword"
                                            />
                                        </div>
                                        {this.props.errMsg
                                            ? this.displayErrors(this.props.errMsg)
                                            : null}
                                        <button className="btn btn-primary btn-form" type="submit">
                                            <Translate content="button.submit"/>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

function validate(values) {
    const errors = {};
    errors.email = validation.newEmail(values.email);

    errors.password = validation.required(values.password);
    errors.password = validation.password(values.password);
    if (values.password !== values.password_confirmation) {
        errors.password_confirmation = "* Pass Mismatch!";
    }
}

function mapStateToProps(state) {
    return {errMsg: state.errors};
}

export default compose(
    connect(mapStateToProps, {getTokenForEmail, changePasswordWithToken}),
    reduxForm({
        validate,
        form: "changePassword"
    })
)(ChangePassword);
