import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { getProfile, editProfile } from "../../../actions/profile";
import Translate from "react-translate-component";
import { inputField, ReactUploader } from "../../../_formFields/";
import * as validation from "../../../_utils/validate";
import {
  displayServerErrors,
  formSubmitFailError,
  displayErrorMessage,
} from "../../../_utils/validate";
import { clearError, clearLoading } from "../../../actions/clearError";
import Loading from "components/general/Loading";
import ReactToolTip from "react-tooltip";
import FileList from "../../general/FileList";

class EditProfile extends Component {
  state = { file: null, pic: null, documents: [] };

  async componentDidMount() {
    await this.props.clearError();
    await this.props.clearLoading();
    await this.props.getProfile();
    this.setState({ documents: this.props.initialValues.documents });
  }

  onCompanyLogoChange = async (e) => {
    await this.setState({ file: e.target.files[0] });
    if (URL) {
      this.setState({
        pic: URL.createObjectURL(this.state.file),
      });
    }
  };

  onSubmit = (formProps) => {
    if (this.state.file) {
      formProps.company_logo = this.state.file;
    }
    if (this.state.documents !== undefined)
      formProps.existing_files = JSON.stringify(
        this.state.documents.map((document) => ({
          file_id: document.id,
        }))
      );
    this.props.editProfile(formProps, () => {
      this.props.history.push("/user/profile");
      sessionStorage.setItem("profileComplete", true);
    });
  };

  render() {
    const { handleSubmit, submitFailed } = this.props;

    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left w-100">
            <div className="d-flex align-items-center editprofile-icon">
              <div className="item position-relative mr-5 company-image--icon">
                {this.props.initialValues ? (
                  <Fragment>
                    <img
                      className="company-logo"
                      src={
                        this.state.pic ||
                        this.props.initialValues.company_logo_link ||
                        "/assets/img/user.png"
                      }
                      alt="alt"
                      width="120px"
                      height="120px"
                    />
                    <img
                      className="verify"
                      src="/assets/img/verify.png"
                      alt="alt"
                    />
                  </Fragment>
                ) : null}
              </div>
              <div className="item position-relative">
                <input
                  className="btn btn-outline-light opacity-none position-absolute z-index-1"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  name=""
                  onChange={this.onCompanyLogoChange}
                />
                <span className="btn btn-outline-light position-absolute btn-change-profile">
                  <Translate content="placeholder.changeProfilePic" />{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <Loading />
          <form className="form-signup" onSubmit={handleSubmit(this.onSubmit)}>
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label>
                    <Translate content="label.companyName" />
                  </label>
                  <i
                    data-tip
                    data-for="company_name"
                    className=" bx bx-info-circle ml-2"
                  >
                    <ReactToolTip id="company_name" type="info">
                      <Translate content="tooltip.company_name" />
                    </ReactToolTip>
                  </i>
                  <Field
                    name="company_name"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.companyName"
                    validate={validation.required}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label>
                    <Translate content="label.headquarter" />
                  </label>
                  <i
                    data-tip
                    data-for="headquarter"
                    className=" bx bx-info-circle ml-2"
                  >
                    <ReactToolTip id="headquarter" type="info">
                      <Translate content="tooltip.headquarter" />
                    </ReactToolTip>
                  </i>
                  <Field
                    name="headquarter"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.headquarterLocation"
                    hideErrorMessage={true}
                    validate={validation.required}
                  />
                  {this.props.syncErrorMessages?.headquarter &&
                    displayErrorMessage(
                      <Translate content="validation.required" />
                    )}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 col-sm-6 col-md-6">
                <div className="form-group">
                  <label>
                    <Translate content="label.streetAddress" />
                  </label>
                  <i
                    data-tip
                    data-for="street_address"
                    className=" bx bx-info-circle ml-2"
                  >
                    <ReactToolTip id="street_address" type="info">
                      <Translate content="tooltip.street_address" />
                    </ReactToolTip>
                  </i>

                  <Field
                    name="street_address"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="label.streetAddress"
                    validate={validation.required}
                    hideErrorMessage={true}
                  />
                  {this.props.syncErrorMessages?.street_address &&
                    displayErrorMessage(
                      <Translate content="validation.required" />
                    )}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6">
                <div className="form-group">
                  <label>
                    <Translate content="label.postalCode" />
                  </label>
                  <i
                    data-tip
                    data-for="postal_code"
                    className=" bx bx-info-circle ml-2"
                  >
                    <ReactToolTip id="postal_code" type="info">
                      <Translate content="tooltip.postal_code" />
                    </ReactToolTip>
                  </i>
                  <Field
                    name="zip_code"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="label.postalCode"
                    validate={validation.required}
                    hideErrorMessage={true}
                  />
                  {this.props.syncErrorMessages?.zip_code &&
                    displayErrorMessage(
                      <Translate content="validation.required" />
                    )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  <Translate content="label.contactPerson" />
                </label>
                <i
                  data-tip
                  data-for="contact_person"
                  className=" bx bx-info-circle ml-2"
                >
                  <ReactToolTip id="contact_person" type="info">
                    <Translate content="tooltip.contact_person" />
                  </ReactToolTip>
                </i>
                <div className="form-group">
                  <Field
                    name="contact_name_1"
                    type="text"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactName"
                    validate={validation.required}
                  />
                  <Field
                    name="contact_email_1"
                    type="email"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactEmail"
                    validate={validation.required}
                  />

                  <Field
                    name="contact_phone_no_1"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.contactPhoneNo"
                    validate={validation.required}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  <Translate content="label.contactPerson" />
                </label>
                <i
                  data-tip
                  data-for="contact_person_2"
                  className=" bx bx-info-circle ml-2"
                >
                  <ReactToolTip id="contact_person_2" type="info">
                    <Translate content="tooltip.contact_person_2" />
                  </ReactToolTip>
                </i>
                <div className="form-group">
                  <Field
                    name="contact_name_2"
                    type="text"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactName2"
                  />
                  <Field
                    name="contact_email_2"
                    type="email"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactEmail2"
                  />

                  <Field
                    name="contact_phone_no_2"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.contactPhoneNo2"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  <Translate content="label.contactPerson" />
                </label>
                <i
                  data-tip
                  data-for="contact_person_3"
                  className=" bx bx-info-circle ml-2"
                >
                  <ReactToolTip id="contact_person_3" type="info">
                    <Translate content="tooltip.contact_person_3" />
                  </ReactToolTip>
                </i>
                <div className="form-group">
                  <Field
                    name="contact_name_3"
                    type="text"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactName3"
                  />
                  <Field
                    name="contact_email_3"
                    type="email"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactEmail3"
                  />

                  <Field
                    name="contact_phone_no_3"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.contactPhoneNo3"
                  />
                </div>
              </div>
            </div>
            <div className="edit-social-media mt-4">
              <label htmlFor="">
                <Translate content="label.socialMedia" />{" "}
              </label>
              <i
                data-tip
                data-for="social_media"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip id="social_media" type="info">
                  <Translate content="tooltip.social_media" />
                </ReactToolTip>
              </i>
              <div className="row edit-social-forms">
                <div className="form-group col-md-3">
                  <Field
                    label="Homepage"
                    name="homepage_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.enterYourWebsite"
                  />
                </div>
               <div className="form-group col-md-3">
                  <Field
                    label="Linkedin"
                    name="linked_in_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.LinkedinURL"
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    label="Xing"
                    name="xing_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.xingURL"
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    label="Facebook"
                    name="facebook_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.facebookURL"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <div className="form-group">
                  <strong>
                    {" "}
                    <Translate content="label.fileUpload" component="label" />
                  </strong>
                  <i
                    data-tip
                    data-for="file"
                    className=" bx bx-info-circle ml-2"
                  >
                    <ReactToolTip id="file" type="info">
                      <Translate content="tooltip.file" />
                    </ReactToolTip>
                  </i>
                  <Field
                    name="document"
                    component={ReactUploader}
                    type="file"
                    className="file-uploader file-uploader--small dropzone"
                  />
                  <FileList
                    documents={this.state.documents}
                    onDocumentsChange={(docId) => {
                      const updatedDocs = this.state.documents.filter(
                        (document) => document.id !== docId
                      );
                      this.setState({ documents: updatedDocs });
                    }}
                    showDownloadAll={true}
                  />
                </div>
              </div>
            </div>
            {this.props.errMsg ? displayServerErrors(this.props.errMsg) : null}
            {formSubmitFailError(submitFailed)}
            <div className="row mt-4">
              <div className="col">
                <button className="btn btn-primary">
                  <Translate content="label.update" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

EditProfile = reduxForm({
  form: "editProfile",
})(EditProfile);

const mapStateToProps = (state) => {
  return {
    initialValues: state.profile,
    errMsg: state.errors,
    syncErrorMessages: state.form.editProfile?.syncErrors,
  };
};

export default connect(mapStateToProps, {
  getProfile,
  editProfile,
  clearLoading,
  clearError,
})(EditProfile);
