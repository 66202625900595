import React, { Component } from "react";
import { connect } from "react-redux";

export default (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      if (!this.props.auth) {
        localStorage.setItem("redirect", this.props.location.pathname);
        this.props.history.push("/");
      } else {
        if (!this.props.profile) return;

        if (
          this.props.profile?.status === "approved" &&
          this.props.profile?.is_profile_complete
        )
          return;

        if (
          this.props.profile?.status === "approved" &&
          !this.props.profile?.is_profile_complete
        ) {
          this.props.history.push("/account-not-complete");
        } else if (this.props.profile?.status === "verified") {
          this.props.history.push("/account-unverified");
        } else if (this.props.profile?.status === "rejected") {
          this.props.history.push("/account-rejected");
        } else {
          this.props.history.push("/account-not-confirmed");
        }
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      auth: state.auth.authenticated,
      profile: state.profile,
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
};
