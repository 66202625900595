import React, { Component } from "react";
import Translate from "react-translate-component";
import ReactToolTip from "react-tooltip";
import { Field } from "redux-form";
import { inputField, ReactUploader, dropDownField } from "../../../_formFields";
import * as validation from "../../../_utils/validate";

import {
  displayServerErrors,
  formSubmitFailError,
} from "./../../../_utils/validate";
import NumberFormat from "react-number-format";
import de from "_locale/de";
import en from "_locale/en";

class PageThreeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratings: [],
    };
  }

  async componentDidMount() {
    await this.props.fetchRatings();
    let ratings = [];
    if (typeof this.props.ratings !== "undefined") {
      ratings = this.props.ratings?.map?.((creditRating) => {
        return {
          label:
            creditRating?.name?.[this.props.language] || creditRating?.name?.de,
          value: creditRating?.code,
          id: creditRating?.id,
        };
      });
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        ratings: ratings,
      };
    });
  }

  increaseCollateralCount = (e) => {
    e.preventDefault();
    let newCount = this.props?.collateralCount + 1;
    this.props.change("collateralCount", newCount);
  };

  decreaseCollateralCount = (e) => {
    e.preventDefault();
    let newCount = this.props?.collateralCount - 1;
    this.props.change(
      `collateral_value_${this.props?.collateralCount}`,
      undefined
    );
    this.props.change(`collateral_${this.props?.collateralCount}`, undefined);

    this.props.change("collateralCount", newCount);
  };

  increaseCreditCount = (e) => {
    e.preventDefault();
    let newCount = this.props?.creditCount + 1;
    this.props.change("creditCount", newCount);
  };

  decreaseCreditCount = (e) => {
    e.preventDefault();
    let newCount = this.props?.creditCount - 1;
    this.props.change("creditCount", newCount);
  };

  renderCredits = () => {
    let creditRatings = [];
    for (let i = 0; i < this.props?.creditCount; i++) {
      creditRatings.push(
        <div className="form-group" style={{ marginBottom: "0rem" }} key={i}>
          <div className="form-row">
            <div className="col-md-6 creditRatingCol">
              <Field
                name={`cr_${i + 1}`}
                type="text"
                placeholder={<Translate content="label.ratingaggentur" />}
                component={dropDownField}
                options={this.state.ratings}
              />
            </div>
            <div className="col-md-6 creditRatingCol">
              <Field
                name={`cr_value_${i + 1}`}
                type="Number"
                placeholder="label.kreditrating"
                component={inputField}
                validate={
                  this.props?.formValues?.[`cr_${i + 1}`] && validation.required
                }
                className="form-control"
              />
            </div>
          </div>
        </div>
      );
    }
    return creditRatings;
  };

  renderCollateral = () => {
    const placeHolderCollateral =
      this.props.language === "de"
        ? de.placeholder.collateral_value
        : en.placeholder.collateral_value;
    let collateral = [];
    for (let i = 0; i < this.props?.collateralCount; i++) {
      collateral.push(
        <div className="form-group" style={{ marginBottom: "0rem" }} key={i}>
          <div className="form-row">
            <div
              className="col-12 col-sm-12 col-md-6 "
              style={{ paddingRight: "15px", marginBottom: "0.75rem" }}
            >
              <Field
                name={`collateral_${i + 1}`}
                type="text"
                component={inputField}
                placeholder="placeholder.LandHouse"
                className="form-control"
              />

              {/* <Field
                name={`collateral_${i + 1}`}
                type="text"
                placeholder="type of security"
                component={dropDownField}
                options={this.state.serviceOptions}
              /> */}
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 loanAmount"
              style={{ paddingLeft: "15px", marginBottom: "0.75rem" }}
            >
              <NumberFormat
                name={`collateral_${i + 1}`}
                placeholder={placeHolderCollateral}
                // defaultValue={0}
                thousandsGroupStyle="thousand"
                decimalSeparator={false}
                value={
                  this.props.oldStateValues?.values?.[
                    `collateral_value_${i + 1}`
                  ] > 0
                    ? this.props.oldStateValues?.values?.[
                        `collateral_value_${i + 1}`
                      ]
                    : undefined
                }
                displayType="input"
                className="loanAmountInput"
                type="text"
                isAllowed={this.withValueLimit}
                onValueChange={(values) =>
                  this.onChangeColalteral(`collateral_value_${i + 1}`, values)
                }
                thousandSeparator={this?.props?.language === "en" ? "," : "."}
              />

              {/* {this?.props?.oldStateValues?.values?.[`collateral_${i + 1}`] &&
                !this?.props?.oldStateValues?.values?.[
                  `collateral_value_${i + 1}`
                ] && (
                  <font color="red">
                    <Translate content="validation.required" />
                  </font>
                )} */}
            </div>
          </div>
        </div>
      );
    }

    return collateral;
  };

  onSubmit = () => {
    // for (let i = 0; i < this.props?.collateralCount; i++) {
    //   if (
    //     this?.props?.oldStateValues?.values?.[`collateral_${i + 1}`] &&
    //     !this?.props?.oldStateValues?.values?.[`collateral_value_${i + 1}`]
    //   )
    //     return;
    // }
    this.props.onPageChange();
  };

  onChangeSales = (values) => {
    this.props.change("sale_amount", values?.floatValue);
  };

  onChangeProfit = (values) => {
    this.props.change("profit_after_tax", values?.floatValue);
  };

  withValueLimit = ({ floatValue }) =>
    floatValue <= 999999999 && floatValue >= 0;

  onChangeColalteral = (field, values) => {
    this.props.change(field, values?.floatValue);
  };

  withValueLimitCollateral = ({ floatValue }) => floatValue >= 0;

  render() {
    const { pageBackChange, handleSubmit } = this.props;

    return (
      <form className="form-signup" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="form-group loanAmount">
              <label>
                {/* <Translate content="label.amountOfSales" /> */}
                <Translate
                  content={
                    this.props.serviceAlternate
                      ? "label.projectTotalSize"
                      : "label.amountOfSales"
                  }
                />
                <strong></strong>
              </label>
              <i
                data-tip
                data-for="amount_of_sales"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="amount_of_sales"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate
                    content={
                      this.props.serviceAlternate
                        ? "tooltip.project_total_size"
                        : "tooltip.amount_of_sales"
                    }
                  />
                </ReactToolTip>
              </i>

              <NumberFormat
                name="sale_amount"
                thousandsGroupStyle="thousand"
                decimalSeparator={false}
                value={this?.props?.oldStateValues?.values?.sale_amount}
                displayType="input"
                className="loanAmountInput"
                type="text"
                isAllowed={this.withValueLimit}
                onValueChange={this.onChangeSales}
                thousandSeparator={this?.props?.language === "en" ? "," : "."}
              />
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <div className="form-group loanAmount">
              <label>
                <Translate
                  content={
                    this.props.serviceAlternate
                      ? "label.projectMargin"
                      : "label.profitAfterTaxes"
                  }
                />
                <strong></strong>
              </label>
              <i
                data-tip
                data-for="profit_after_taxes"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="profit_after_taxes"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate
                    content={
                      this.props.serviceAlternate
                        ? "tooltip.project_margin"
                        : "tooltip.profit_after_taxes"
                    }
                  />
                </ReactToolTip>
              </i>

              <NumberFormat
                name="profit_after_tax"
                thousandsGroupStyle="thousand"
                decimalSeparator={false}
                value={this?.props?.oldStateValues?.values?.profit_after_tax}
                displayType="input"
                className="loanAmountInput"
                type="text"
                isAllowed={this.withValueLimit}
                onValueChange={this.onChangeProfit}
                thousandSeparator={this?.props?.language === "en" ? "," : "."}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <label className="d-block">
              <Translate content="label.collateral" />
              <i
                data-tip
                data-for="collateral"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  className="tooltip-width"
                  id="collateral"
                  type="info"
                >
                  <Translate content="tooltip.collateral" />
                </ReactToolTip>
              </i>
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="form-group">
              {this.renderCollateral()}
              <div className="form-group-actions d-flex justify-content-between">
                <span
                  className="collateralLink"
                  onClick={this.increaseCollateralCount}
                >
                  <i className="bx bx-plus bx-xs"> </i>
                  <Translate content="label.weitereSicherheitenHinzufügen" />
                </span>
                {this.props?.collateralCount > 0 ? (
                  <span
                    className="collateralLink"
                    onClick={this.decreaseCollateralCount}
                  >
                    <i className="bx bx-minus bx-xs"> </i>
                    <Translate content="label.lastCollateralRemove" />
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {!this.props.serviceAlternate && (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label className="d-block">
                <Translate content="label.ratingForCredit" />
                <i
                  data-tip
                  data-for="rating_for_credit"
                  className=" bx bx-info-circle ml-2"
                >
                  <ReactToolTip
                    id="rating_for_credit"
                    type="info"
                    className="tooltip-width"
                  >
                    <Translate content="tooltip.ratingForCredit" />
                  </ReactToolTip>
                </i>
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="form-group">
                {this.renderCredits()}
                <div className="form-group-actions d-flex justify-content-between">
                  <span
                    className="collateralLink"
                    onClick={this.increaseCreditCount}
                  >
                    <i className="bx bx-plus bx-xs"> </i>
                    <Translate content="label.weitereRatingaggenturHinzufügen" />
                  </span>
                  {this.props?.creditCount > 0 ? (
                    <span
                      className="collateralLink"
                      onClick={this.decreaseCreditCount}
                    >
                      <i className="bx bx-minus bx-xs"> </i>
                      <Translate content="label.lastRatingAgentRemove" />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <label>
              <Translate content="label.NDARequirement" />
            </label>
            <i
              data-tip
              data-for="nda-requirement"
              className=" bx bx-info-circle ml-2"
            >
              <ReactToolTip
                id="nda-requirement"
                type="info"
                className="tooltip-width"
              >
                <Translate content="tooltip.NDARequirement" />
              </ReactToolTip>
            </i>
          </div>
          <div className="col-md-12">
            <a href="/NDA_piHub__1_.pdf" target="_blank">
              NDA_piHub.pdf{" "}
              <Translate content="label.view" component="strong" />
            </a>
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <Field
                name="confidential_statement"
                component={ReactUploader}
                initialFiles={
                  this.props.oldStateValues?.values?.confidential_statement
                }
                type="file"
                className="file-uploader file-uploader--small dropzone"
              />
              <div className="mt-3">
                <Field
                  name="nda_requirement"
                  id="nda_requirement"
                  component="input"
                  type="checkbox"
                />
                <span className="pl-2">
                  <Translate content="label.NDARequirementStatement" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.props.errMsg ? displayServerErrors(this.props.errMsg) : null}
        {formSubmitFailError(this.props.submitFailed)}
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 mt-4 backBtn">
            <button
              className="btn btn-secondary"
              onClick={pageBackChange}
              type="button"
            >
              <Translate content="label.back" />
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6 mt-4">
            <button
              className="btn btn-primary"
              style={{ float: "right" }}
              type="submit"
              // onClick={this.props.onPageChange}
            >
              <Translate content="label.continue" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default PageThreeForm;
