import client from "./index";
import { routes } from "_api/routes";
import { FETCH_SERVICES, ERROR } from "actions/types";
import { extractServerErrors } from "../_utils/validate";

export const fetchServices = () => async dispatch => {
  try {
    const response = await client.get(`${routes.fetchServices}`);

    dispatch({
      type: FETCH_SERVICES,
      payload: response.data.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};
