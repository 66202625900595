const API_URL = process.env.REACT_APP_API_URL;
export const routes = {
  login: `${API_URL}/login`,
  logout: `${API_URL}/logout`,
  signup: `${API_URL}/register`,
  getNotificationList: `${API_URL}/me/notifications`,
  countNotification: `${API_URL}/me/notification/count-new`,
  markAsRead: `${API_URL}/me/notification/read`,
  changeStatusOfRequest: `${API_URL}/application`,
  fetchIndustries: `${API_URL}/industries`,
  fetchServices: `${API_URL}/services`,
  addCorporateInformation: `${API_URL}/creditor/corporate-information`,
  application: `${API_URL}/creditor/applications`,
  creditorProduct: `${API_URL}/creditor/products`,
  productStatus: `${API_URL}/creditor/applications`,
  ratings: `${API_URL}/ratings`,
  states: `${API_URL}/states`,
  getProfile: `${API_URL}/me`,
  passwordReset: `${API_URL}/password-reset-token`,
  changePasswordWithToken: `${API_URL}/change-password-with-token`,
  downloadToken: `${API_URL}/download-token`,
  downloadFile: `${API_URL}/download`,
  emailVerification: `${API_URL}/email-verification`,
  confirmationLink: `${API_URL}/confirmation-link`,
  fetchSuggestions: `${API_URL}/open-corporates`,
};
