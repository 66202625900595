import client from "./index";
import { routes } from "_api/routes";
import {
  ADD_APPLICATION,
  ERROR,
  FETCH_APPLICATION,
  FETCH_MATCH_APPLICATION,
  LIST_APPLICATION,
  PAGINATION,
  ERROR_FIELDS,
  CLEAR_ERROR_FIELDS,
  CLEAR_PAGEONE_FORM,
  CLEAR_PAGETHREE_FORM,
  CLEAR_PAGETWO_FORM,
  CLEAR_PAGEFOUR_FORM,
  CLEAR_PAGEONEEDIT_FORM,
  CLEAR_PAGETHREEEDIT_FORM,
  CLEAR_PAGETWOEDIT_FORM,
  CLEAR_PAGEFOUREDIT_FORM,
  ADD_APPLICATION_PAGE_STATE,
} from "actions/types";
import { LOADING, LOADING_DONE } from "./types";
import {
  extractServerErrors,
  extractServerErrorFields,
} from "../_utils/validate";

const Translator = require("react-translate-component");

export const changePage = (data) => async (dispatch) => {
  dispatch({
    type: ADD_APPLICATION_PAGE_STATE,
    payload: data,
  });
};

export const resetForm = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PAGEONE_FORM,
    payload: {},
  });
  dispatch({
    type: CLEAR_PAGETWO_FORM,
    payload: {},
  });
  dispatch({
    type: CLEAR_PAGETHREE_FORM,
    payload: {},
  });
  dispatch({
    type: CLEAR_PAGEFOUR_FORM,
    payload: {},
  });
};

export const resetFormEdit = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PAGEONEEDIT_FORM,
    payload: {},
  });
  dispatch({
    type: CLEAR_PAGETWOEDIT_FORM,
    payload: {},
  });
  dispatch({
    type: CLEAR_PAGETHREEEDIT_FORM,
    payload: {},
  });
  dispatch({
    type: CLEAR_PAGEFOUREDIT_FORM,
    payload: {},
  });
};

export const resetFetchApplication = () => async (dispatch) => {
  dispatch({
    type: FETCH_APPLICATION,
    payload: {},
  });
};

export const addApplication = (data, callback) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: { URL: routes.application, method: "post", data: data },
  });
  try {
    dispatch({
      type: CLEAR_ERROR_FIELDS,
      payload: null,
    });

    const response = await client.post(routes.application, data);
    dispatch({
      type: ADD_APPLICATION,
      payload: response,
    });

    callback();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data),
      });
      dispatch({
        type: ERROR_FIELDS,
        payload:
          typeof e.response === "undefined"
            ? null
            : extractServerErrorFields(e.response.data),
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.application, method: "post", data: data },
    });
  }
};

export const fetchApplications =
  (page = 1) =>
  async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.application}?page=${page}`,
        method: "get",
        data: page,
      },
    });
    try {
      const response = await client.get(`${routes.application}?page=${page}`);

      dispatch({
        type: LIST_APPLICATION,
        payload: response.data,
      });

      dispatch({
        type: PAGINATION,
        payload: response.data.meta.last_page,
      });
    } catch (e) {
      if (e.isAxiosError) {
        dispatch({
          type: ERROR,
          payload:
            typeof e.response === "undefined"
              ? e.message
              : extractServerErrors(e.response.data),
        });
      }
    } finally {
      dispatch({
        type: LOADING_DONE,
        payload: {
          URL: `${routes.application}?page=${page}`,
          method: "get",
          data: page,
        },
      });
    }
  };

const prepareApplicationData = (data) => {
  let preparedData = {
    service_id: {
      value: data.service.id,
      label: data.service.name[Translator.getLocale()],
    },
    states: { value: data.state.id, label: data.state.name },
    industry: {
      value: data.industry.id,
      label: data.industry.name[Translator.getLocale()],
    },
    county: { value: data.county.id, label: data.county.name },
    description: data.description,
    collateral: data.collateral,
    nda_requirement: data.nda_requirement,
    financial_need: data.amount,
    time_duration: data.time_duration,
    deadline: data.deadline,
    payment_after: data.payment_after,
    sale_amount: data.sales,
    profit_after_tax: data.profit_after_tax,
    collateralCount: data.collaterals.length,
    credit_ratings: data.ratings,
    rating_for_credit:
      data.ratings && data.ratings.length > 0 && data.ratings !== "undefined"
        ? 1
        : 0,
    all_files: data.files,
  };
  if (data.nda_requirement) {
    preparedData["tc"] = true;
  }
  const collateral_data = {};
  let countTemp = 1;
  for (let collaterals of data.collaterals) {
    preparedData["collateral_" + countTemp] = collaterals.name;
    preparedData["collateral_value_" + countTemp] = collaterals.value;
    collateral_data["collateral_" + countTemp] = collaterals.name;
    collateral_data["collateral_value_" + countTemp] = collaterals.value;
    countTemp++;
  }
  preparedData.collateral_data = collateral_data;

  for (let ratings of data.ratings) {
    preparedData["cr_value_" + ratings.id] = ratings.value;
    preparedData["checkbox_" + ratings.id] = true;
  }

  for (let file of data.files) {
    let tempFileObj = {};
    tempFileObj.name = file.file_name; // set paths as filename @todo (get proper file and file name to populate)
    if (file.type in preparedData && preparedData[file.type]) {
      preparedData[file.type].push(tempFileObj);
    } else {
      preparedData[file.type] = [];
      preparedData[file.type].push(tempFileObj);
    }
  }

  return preparedData;
};

export const fetchApplication = (id) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: { URL: `${routes.application}/${id}`, method: "get", data: id },
  });
  try {
    const response = await client.get(`${routes.application}/${id}`);

    dispatch({
      type: FETCH_APPLICATION,
      payload: prepareApplicationData(response.data.data),
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data),
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: `${routes.application}/${id}`, method: "get", data: id },
    });
  }
};

export const editApplication = (id, data, callback) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.application}/${id}`,
      method: "post",
      data: data,
    },
  });

  try {
    const response = await client.post(`${routes.application}/${id}`, data);

    if (response) {
      dispatch({
        type: LOADING_DONE,
        payload: {
          URL: `${routes.application}/${id}`,
          method: "post",
          data: data,
        },
      });
      callback();
    }
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data),
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.application}/${id}`,
        method: "post",
        data: data,
      },
    });
  }
};

export const fetchMatchApplications = (id) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.application}/${id}/products`,
      method: "get",
      data: id,
    },
  });
  try {
    const response = await client.get(`${routes.application}/${id}/products`);
    dispatch({
      type: FETCH_MATCH_APPLICATION,
      payload: response.data,
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data),
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.application}/${id}/products`,
        method: "get",
        data: id,
      },
    });
  }
};
