import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";

import PageFourForm, { filesList } from "../forms/PageFourForm";

class PageFour extends Component {
  render() {
    return <PageFourForm {...this.props} />;
  }
}

PageFour = reduxForm({
  form: "PageFour",
  destroyOnUnmount: false,
  validate,
})(PageFour);

function validate(values) {
  const error = {};
  const check = filesList.every((e) => Boolean(values[e]));
  if (!check) error.showInfoErrorMessage = true;
  return error;
}

const selector = formValueSelector("PageFour");

const mapStateToProps = (state) => {
  const applicationFiles = selector(state, "applicationFiles");
  const serviceAlternate = state.form.PageTwo?.values.service_id?.value === 5;
  const initialFiles = { ...state.form.PageFour };

  return {
    initialFiles,
    serviceAlternate,
    applicationFiles,
    errMsg: state.errors,
    applicationDetails: state?.fetchApplication?.application,
  };
};

export default connect(mapStateToProps, {})(PageFour);
