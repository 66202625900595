import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import counterpart from "counterpart";
import en from "./_locale/en";
import de from "./_locale/de";
import App from "./components/App";
import reducers from "./reducers";
import RequireInvestorAuth from "./components/_auth/RequireInvestorAuth";
import RequireNoAuth from "./components/_auth/RequireNoAuth";
import RequireVerification from "./components/_auth/RequireVerification";
import Signup from "./components/user/Signup";
import SignUpActivated from "./components/user/signup/Activated";
import ConfirmEmail from "./components/user/signup/ConfirmEmail";
import Approval from "./components/user/signup/Approval";
import Login from "./components/user/Login";
import ForgotPassword from "./components/user/ForgotPassword";
import SetPassword from "./components/user/SetPassword";
import ChangePassword from "./components/user/signup/ChangePassword";
import PasswordChangeSuccess from "./components/general/PasswordChangeSuccess";
import Notifications from "./components/notifications/List";
import ListApplication from "./components/applications/List";
import MatchApplication from "./components/applications/Match";
import View from "./components/applications/View";
import OfferReceived from "./components/applications/OfferReceived";
import ViewProfile from "./components/user/profile/ViewProfile";
import EditProfile from "./components/user/profile/EditProfile";
import UnverifiedPage from "./components/general/UnverifiedPage";
import UnconfirmedPage from "./components/general/UnconfirmedPage";
import TermsCondition from "./components/general/TermsCondition";
import PrivacyPolicy from "./components/general/PrivacyPolicy";
import PopUp from "./components/general/PopUp";
import Error404 from "./components/general/Error404";
import ApplyForLoan from "./components/applications/apply-for-loan";
import Dashboard from "./actions/dashboard.js";
// import EditLoan from "components/applications/apply-for-loan/Edit";
import EditLoan from "components/applications/edit-application";
import UncompletePage from "components/general/UncompletePage";
import Rejected from "components/general/Rejected";

//End of component import
counterpart.registerTranslations("en", en);
counterpart.registerTranslations("de", de);
// counterpart.setLocale(localStorage.getItem('language') || navigator.language.split('-')[0] || 'de');
//todo german language is set static for now. remove the static line later.
counterpart.setLocale(localStorage.getItem("language") || "de");

const store = createStore(
  reducers,
  {
    auth: {
      authenticated: localStorage.getItem("token"),
    },
  },
  applyMiddleware(reduxThunk)
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        {/** ___ Start: NO AUTH ROUTES --- Authenticated users should be redirected to home screen ---*/}
        <Route path="/" exact component={RequireNoAuth(Login)} />
        <Route
          path="/set-password/:token"
          exact
          component={RequireNoAuth(SetPassword)}
        />
        <Route
          path="/forgot-password"
          exact
          component={RequireNoAuth(ForgotPassword)}
        />
        <Route path="/terms-and-conditions" component={TermsCondition} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/signup" exact component={RequireNoAuth(Signup)} />
        <Route
          path="/change-password"
          component={RequireInvestorAuth(ChangePassword)}
        />
        <Route
          path="/dashboard"
          exact
          component={RequireInvestorAuth(App(Dashboard))}
        />
        {/** --- End: NO AUTH ROUTES --- Authenticated users should be redirected to home screen ___ */}
        {/** ___ Start: signup protected routes (auth for these routes handled within component itself) --- */}
        <Route path="/signup/activated" exact component={SignUpActivated} />
        {/** Logged in and activated accounts investors only*/}
        <Route
          path="/signup/confirm-email"
          exact
          component={ConfirmEmail}
        />{" "}
        {/** Logged in (there should be a token) and unconfirmed accounts only*/}
        <Route path="/confirm/:hash" exact component={Approval} />{" "}
        {/** Logged in (there should be a token) - confirmed but waiting to e approved accounts only*/}
        <Route path="/signup/approval" exact component={Approval} />{" "}
        {/** Logged in (there should be a token) - confirmed but waiting to e approved accounts only*/}
        <Route
          path="/password-change-success"
          component={PasswordChangeSuccess}
        />
        {/** --- End: signup protected routes ___ */}
        {/** ___ Start: Authenticated User's routes --- */}
        <Route
          exact
          path="/notifications"
          component={RequireInvestorAuth(App(Notifications))}
        />
        {/** --- End: Authenticated User's routes ___ */}
        <Route
          exact
          path={"/apply-for-loan"}
          component={RequireInvestorAuth(
            RequireVerification(App(ApplyForLoan))
          )}
        />
        <Route
          exact
          path={"/edit-application/:application_id"}
          component={RequireInvestorAuth(RequireVerification(App(EditLoan)))}
        />
        <Route
          exact
          path="/list-application"
          component={RequireInvestorAuth(
            RequireVerification(App(ListApplication))
          )}
        />
        <Route
          exact
          path="/detail"
          component={RequireInvestorAuth(RequireVerification(App(View)))}
        />
        <Route
          exact
          path="/offer_received"
          component={RequireInvestorAuth(
            RequireVerification(App(OfferReceived))
          )}
        />
        <Route
          exact
          path="/match-application/:application_id"
          component={RequireInvestorAuth(
            RequireVerification(App(MatchApplication))
          )}
        />
        {/* Profile */}
        <Route
          path="/user/profile"
          component={RequireInvestorAuth(App(ViewProfile))}
        />
        <Route
          path="/user/edit-profile"
          component={RequireInvestorAuth(App(EditProfile))}
        />
        <Route
          path="/account-unverified"
          component={RequireInvestorAuth(App(UnverifiedPage))}
        />
        <Route
          path="/account-not-confirmed"
          component={RequireInvestorAuth(App(UnconfirmedPage))}
        />
        <Route
          path="/account-not-complete"
          component={RequireInvestorAuth(App(UncompletePage))}
        />
        <Route
          path="/account-rejected"
          component={RequireInvestorAuth(App(Rejected))}
        />
        {/*DESIGN COMPONENT*/}
        <Route path="/popup" component={PopUp} />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.querySelector("#root")
);
