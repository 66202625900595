import React, { Component } from "react";
import Translate from "react-translate-component";
import ReactToolTip from "react-tooltip";
import { Field } from "redux-form";
import { ReactUploader } from "../../../_formFields";
import Accordion from "../../general/Accordion";
import FileList from "../../general/FileList";

import {
  displayServerErrors,
  formSubmitFailError,
  displayFileInfoErrorMessage,
} from "../../../_utils/validate";

export const filesList = [
  "balance_sheet",
  "financial_plan",
  "trade_register_excerpt",
  "bylaws_articles",
  "latest_bwa",
  "list_of_shareholders",
  "business_plan",
  "information_about_owner",
];
class PageFourForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [
        {
          name: (
            <Translate
              content={
                this.props.serviceAlternate
                  ? "label.balanceSheetProjectFinancing"
                  : "label.balanceSheet"
              }
            />
          ),
          tip: <Translate content="tooltip.balanceSheet" />,
          id: "balance_sheet",
        },
        {
          name: (
            <Translate
              content={
                this.props.serviceAlternate
                  ? "label.financialPlanProjectFinancing"
                  : "label.financialPlan"
              }
            />
          ),
          tip: <Translate content="tooltip.financialPlan" />,
          id: "financial_plan",
        },
        {
          name: (
            <Translate
              content={
                this.props.serviceAlternate
                  ? "label.tradeRegisterExcerptProjectFinancing"
                  : "label.tradeRegisterExcerpt"
              }
            />
          ),
          tip: <Translate content="tooltip.tradeRegisteExcerpt" />,
          id: "trade_register_excerpt",
        },

        {
          name: (
            <Translate
              content={
                this.props.serviceAlternate
                  ? "label.bylawsArticlesOfIncorporationProjectFinancing"
                  : "label.bylawsArticlesOfIncorporation"
              }
            />
          ),
          tip: <Translate content="tooltip.bylawsArticlesOfIncorporation" />,
          id: "bylaws_articles",
        },

        {
          name: (
            <Translate
              content={
                this.props.serviceAlternate
                  ? "label.latestBwaProjectFinancing"
                  : "label.latestBwa"
              }
            />
          ),
          tip: <Translate content="tooltip.latestBwa" />,
          id: "latest_bwa",
        },
        {
          name: <Translate content="label.listOfShareholders" />,
          tip: <Translate content="tooltip.listOfShareholders" />,
          id: "list_of_shareholders",
        },
        {
          name: <Translate content="label.businessPlan" />,
          tip: <Translate content="tooltip.businessPlan" />,
          id: "business_plan",
        },
        {
          name: <Translate content="label.informationAboutOwner" />,
          tip: <Translate content="tooltip.informationAboutOwner" />,
          id: "information_about_owner",
        },
        {
          name: <Translate content="label.otherDocuments" />,
          tip: <Translate content="tooltip.otherDocuments" />,
          id: "other_documents",
        },
      ],
      fileErrorMessages: {},
    };
  }
  deleteFile = (fileId) => {
    const newFiles = this.props?.applicationFiles?.filter(
      (applicationFile) => applicationFile.id !== fileId
    );
    this.props.change("applicationFiles", newFiles);
  };

  renderFiles = () => {
    let files = [...this.state.files];
    return files.map((file) => {
      if (file.id === "business_plan" && this.props.serviceAlternate)
        return null;
      return (
        <div className="col-md-12 accordionSection" key={file.id}>
          <div className="form-group">
            <Accordion
              title={
                <>
                  <label>{file.name}</label>
                  <i
                    data-tip
                    data-for={file.id}
                    className=" bx bx-info-circle ml-2"
                  >
                    <ReactToolTip
                      id={file.id}
                      type="info"
                      className="tooltip-width"
                    >
                      {file.tip}
                    </ReactToolTip>
                  </i>
                  {this.state.fileErrorMessages?.[file.id] && (
                    <font color="red">
                      <Translate content="validation.required" />
                    </font>
                  )}
                </>
              }
            >
              <Field
                name={file.id}
                component={ReactUploader}
                initialFiles={this.props.initialFiles?.values?.[file.id]}
                type="file"
                // validate={file.required ? validation.required : null}
                className="file-uploader file-uploader--small dropzone"
              />
              {this.props?.pageEdit && (
                <div>
                  <strong>Filename:</strong> <br />
                  <FileList
                    documents={this.props?.applicationFiles?.filter(
                      (applicationFile) => applicationFile.type === file.id
                    )} //send documents matching type with file id
                    onDocumentsChange={this.deleteFile}
                    showDownloadAll={false} //downloadd is not required to show if the component is being used in edit pate
                  />
                </div>
              )}
            </Accordion>
          </div>
        </div>
      );
    });
  };

  validate = (values, field) => {
    if (
      values?.[field]?.length > 0 ||
      values?.applicationFiles?.find((e) => e?.type === field)
    ) {
      return false;
    }
    return true;
  };

  onSubmit = (values) => {
    const errors = {};
    errors.balance_sheet = this.validate(values, "balance_sheet");
    errors.financial_plan = this.validate(values, "financial_plan");

    this.setState((prevState) => {
      return { ...prevState, fileErrorMessages: errors };
    });

    if (!errors.balance_sheet && !errors.financial_plan) {
      this.props.onFormSubmit();
    }
  };

  render() {
    const { pageBackChange, handleSubmit } = this.props;

    return (
      <form className="form-signup" onSubmit={handleSubmit(this.onSubmit)}>
        <p>
          <i className=" bx bx-info-circle ml-2">
            <font className="pl-2 green-text tooltip-width">
              <Translate content="info.file_upload_info" />
            </font>
          </i>
        </p>
        <div className="row">{this.renderFiles()}</div>

        {this.props.errMsg ? displayServerErrors(this.props.errMsg) : null}
        {formSubmitFailError(this.props.submitFailed)}

        {this.props.initialFiles?.syncErrors?.showInfoErrorMessage &&
          displayFileInfoErrorMessage(
            <Translate content="info.file_upload_error_info" />
          )}

        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 mt-4 backBtn">
            <button
              className="btn btn-secondary"
              onClick={pageBackChange}
              type="button"
            >
              <Translate content="label.back" />
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6 mt-4">
            <button
              className="btn btn-primary"
              style={{ float: "right" }}
              type="submit"
              // onClick={onFormSubmit}
            >
              <Translate content="button.save" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default PageFourForm;
