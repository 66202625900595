import { FETCH_STATES, FETCH_COUNTIES } from "actions/types";

const initialState = {
  states: [],
  counties: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATES:
      return {
        ...state,
        states: action.payload
      };
    case FETCH_COUNTIES:
      return {
        ...state,
        counties: action.payload
      };

    default:
      return state;
  }
}
