import React, { Component } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { logout } from "../../actions/login";

// eslint-disable-next-line import/no-anonymous-default-export
export default (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }

    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      // give false condition
      if (!this.props.auth) {
        this.props.history.push("/");
      } else {
        const { exp } = jwt_decode(this.props.auth);

        if (exp * 1000 < new Date().getTime()) {
          this.props.logout(() => this.props.history.push("/"));
        }
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      auth: state.auth.authenticated,
    };
  }

  return connect(mapStateToProps, { logout })(ComposedComponent);
};
