import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";
export default props => {
  return (
    <Fragment>
      <img
        class="company-logo company-logo-email"
        src="./assets/img/logo.png"
        alt="company logo"
      />
      <div class="container-full-height text-centerd d-flex">
        <div class="content m-auto">
          <div class="email-content text-center w-75 m-auto">
            <img src="../assets/img/icons/password-lock.svg" alt="Mail icon" />
            <h3>
              <Translate content="label.passwordChangedSuccessful" />
            </h3>
            <p>
              <Translate content="label.youCanUseYourNewPassword" />{" "}
            </p>
            <br />
            <Link to="/">
              <strong>
                <Translate content="button.login" />
              </strong>
            </Link>{" "}
            <Translate content="label.withYourNewPassword" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
