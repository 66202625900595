import React, {Fragment, Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getProfile} from "../../../actions/profile";
import Translate from "react-translate-component";
import Loading from "components/general/Loading";
import FileList from "../../general/FileList";

class ViewProfile extends Component {
    componentDidMount() {
        this.props.getProfile();
    }

    render() {
        if (!this.props.profile) {
            return <Loading/>;
        } else {
            const {
                company_name,
                status,
                category,
                company_logo_link,
                contact_email_1,
                contact_email_2,
                contact_email_3,
                contact_phone_no_1,
                contact_phone_no_2,
                contact_phone_no_3,
                contact_name_1,
                contact_name_2,
                contact_name_3,
                facebook_link,
                twitter_link,
                street_address,
                headquarter,
                zip_code,
                linked_in_link,
                homepage_link,
                xing_link,
                documents
            } = this.props.profile;
            return (
                <Fragment>
                    <div className="content-head row align-items-start">
                        <div className="content-head-left w-50 col-12 col-sm-12 col-md-8 col-lg-8">
                            <div className="d-flex company-image">
                                <div className="item position-relative company-image--icon">
                                    <img
                                        className="company-logo"
                                        src={
                                            company_logo_link
                                                ? company_logo_link
                                                : "/assets/img/user.png"
                                        }
                                        alt="company logo"
                                    />
                                    <img
                                        className="verify"
                                        src="/assets/img/verify.png"
                                        alt="alt"
                                    />
                                </div>
                                <div className="item ml-4">
                                    <div className="d-flex profile-heading">
                                        <h2 className="mb-0">{company_name} </h2>
                                        <span className="badge-primary p-2 rounded ml-2">
                      {status === "approved" ? (
                          <Translate content="placeholder.verified"/>
                      ) : (
                          <Translate content="placeholder.unverified"/>
                      )}
                    </span>
                                    </div>
                                    <div className="d-flex social-media mt-3">
                                        {homepage_link ? (
                                            <a
                                                href={getValidUrl(homepage_link)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    className="mr-3"
                                                    src="/assets/img/icons/domain.png"
                                                    alt="alt"
                                                    width="25px"
                                                    height="25px"
                                                />
                                            </a>
                                        ) : null}
                                        {facebook_link ? (
                                            <a
                                                href={getValidUrl(facebook_link)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    className="mr-3"
                                                    src="/assets/img/icons/facebook.png"
                                                    alt="alt"
                                                    width="25px"
                                                    height="25px"
                                                />
                                            </a>
                                        ) : null}
                                        {twitter_link ? (
                                            <a
                                                href={getValidUrl(twitter_link)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    className="mr-3"
                                                    src="/assets/img/icons/twitter.png"
                                                    alt="alt"
                                                    width="25px"
                                                    height="25px"
                                                />
                                            </a>
                                        ) : null}
                                        {linked_in_link ? (
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={getValidUrl(linked_in_link)}
                                            >
                                                <img
                                                    className="mr-3"
                                                    src="/assets/img/icons/linkedin.png"
                                                    alt="alt"
                                                    width="25px"
                                                    height="25px"
                                                />
                                            </a>
                                        ) : null}
                                        {xing_link ? (
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={getValidUrl(xing_link)}
                                            >
                                                <img
                                                    src="/assets/img/icons/xing.png"
                                                    alt="alt"
                                                    width="25px"
                                                    height="25px"
                                                />
                                            </a>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-head-right col-12 col-sm-12 col-md-4 col-lg-4">
                            <Link
                                className="btn btn-primary float-right"
                                to="/user/edit-profile"
                            >
                                <Translate content="button.editProfile"/>{" "}
                            </Link>
                        </div>
                    </div>
                    <div className="content-body mt-5">
                        {category ? (
                            <div className="d-flex">
                                <img src="/assets/img/bx-briefcase.png" alt=""/>
                                <span className="ml-3 font-weight-bold">
                  <div className="d-flex">
                    <span className="mr-3">
                      <Translate content="column.category"/>:
                    </span>
                    <span>
                      {category
                          ? category.charAt(0).toUpperCase() + category.slice(1)
                          : category}
                    </span>
                  </div>
                </span>
                            </div>
                        ) : null}
                        {street_address && headquarter && {zip_code} ? (
                            <div className="d-flex mt-4">
                                <figure className="m-0">
                                    <img src="/assets/img/bx-map.png" alt=""/>
                                </figure>
                                <p className="ml-3 font-weight-bold mb-0">
                                    {street_address} <br/>
                                    {headquarter}, {zip_code}
                                </p>
                            </div>
                        ) : null}
                        <div className="d-flex mt-4 phone">
                            <img
                                src="/assets/img/bx-phone.png"
                                alt=""
                                width="20px"
                                height="20px"
                            />
                            <div className="d-flex flex-column">
                                <h4 className="ml-3 font-weight-bold">{contact_name_1}</h4>
                                <span className="ml-3 mb-2">{contact_email_1}</span>
                                <span className="ml-3">{contact_phone_no_1}</span>
                            </div>
                        </div>
                        {contact_email_2 ||
                        contact_email_3 ||
                        contact_name_2 ||
                        contact_name_3 ||
                        contact_phone_no_2 ||
                        contact_phone_no_3 ? (
                            <div>
                                <div className="d-flex mt-4">
                                    <img
                                        src="/assets/img/icons/contact-person.png"
                                        alt=""
                                        width="20px"
                                    />
                                    <span className="ml-3 font-weight-bold">
                    <Translate content="label.otherContactPerson"/>{" "}
                  </span>
                                </div>
                                <div className="d-flex mt-4 contact_person">
                                    <div className="item d-flex flex-column">
                                        <h5 className="font-weight-normal">{contact_name_2} </h5>
                                        <span className="mb-2">{contact_email_2}</span>
                                        <span>{contact_phone_no_2}</span>
                                    </div>

                                    <div className="item ml-4 d-flex flex-column">
                                        <h5 className="font-weight-normal">{contact_name_3} </h5>
                                        <span className="mb-2">{contact_email_3}</span>
                                        <span>{contact_phone_no_3}</span>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="d-flex mt-4 phone">
                            <i className='bx bxs-file-doc' ></i>
                            <div className="d-flex flex-column">
                                <h4 className="ml-3 font-weight-bold">Documents</h4>
                                <FileList documents={documents} showDownloadAll={true} />
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

function mapStateToProps(state) {
    return {profile: state.profile};
}

function getValidUrl(url) {
    let pattern = /^((http|https|ftp):\/\/)/;

    if (!pattern.test(url)) {
        url = "http://" + url;
    }
    return url;
}

export default connect(mapStateToProps, {getProfile})(ViewProfile);
