import { ERROR_FIELDS, CLEAR_ERROR_FIELDS } from "../actions/types";

export default function (state = null, action) {
  switch (action.type) {
    case ERROR_FIELDS:
      return action.payload;
    case CLEAR_ERROR_FIELDS:
      return null;
    default:
      return state;
  }
}
