import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { fetchRatings } from "actions/ratings";
import PageThreeForm from "../forms/PageThreeForm";

class PageThree extends Component {
  render() {
    return <PageThreeForm {...this.props} />;
  }
}

PageThree = reduxForm({
  form: "PageThree",
  destroyOnUnmount: false,
})(PageThree);

const selector = formValueSelector("PageThree");

const mapStateToProps = (state) => {
  const collateralCount = selector(state, "collateralCount");
  const creditCount = selector(state, "creditCount");

  const oldStateValues = { ...state?.form?.PageThree };
  const serviceAlternate = state.form.PageTwo?.values.service_id?.value === 5;

  const initialValues = {
    sale_amount: 0,
    collateralCount: 1,
    profit_after_tax: 0,
    creditCount: 1,
  };

  return {
    oldStateValues,
    creditCount,
    ratings: state.ratings.ratings.data,
    collateralCount,
    serviceAlternate,
    errMsg: state.errors,
    language: state.language,
    initialValues: { ...initialValues },
    formValues: state?.form?.PageThree?.values,
  };
};

export default connect(mapStateToProps, { fetchRatings })(PageThree);
