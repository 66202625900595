import React, { Component } from "react";

import Translate from "react-translate-component";
import ReactToolTip from "react-tooltip";
import { Field } from "redux-form";
import {
  inputField,
  inputSlider,
  renderDatePicker,
} from "../../../_formFields";
import moment from "moment";

import {
  displayServerErrors,
  formSubmitFailError,
} from "./../../../_utils/validate";
import * as validation from "_utils/validate";

import NumberFormat from "react-number-format";

const maxLoanAmount = 10000000;
const minLoanAmount = 500000;

class PageOneForm extends Component {
  onChangeFinancialNeed = (values) => {
    this.props.change("financial_need", values?.floatValue);
  };

  withValueLimit = ({ floatValue }) => floatValue <= maxLoanAmount;

  onSubmit = () => {
    if (this?.props?.oldStateValues?.values?.financial_need >= minLoanAmount) {
      this.props.onPageChange();
    }
  };

  render() {
    const { handleSubmit } = this.props;

    const threeWeeksLater = moment()
      .add(21, "d") //replace first-param with number of days you want to add
      .toDate(); //convert it to a Javascript Date Object if you like

    const twoWeeksFromOfferDate = moment(threeWeeksLater).add(14, "d").toDate();
    return (
      <form className="form-signup" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="form-group">
              <label>
                <Translate content="label.financialNeeds" />
              </label>
              <i
                data-tip
                data-for="financial_need"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="financial_need"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate content="tooltip.financial_need" />
                </ReactToolTip>
              </i>
              <div className="form-group-ranger loanAmount">
                <Field
                  name="financial_need"
                  type="range"
                  component={inputSlider}
                  className="w-100"
                  id="financial-need"
                  min={minLoanAmount}
                  max={maxLoanAmount}
                  step={10000}
                  defaultValue={
                    this?.props?.oldStateValues?.values?.financial_need
                  }
                />
                {this?.props?.oldStateValues?.values?.financial_need <
                  minLoanAmount && (
                  <span className="loanAmountValidation">
                    <Translate content="validation.greater_than_financial" />
                  </span>
                )}

                <NumberFormat
                  name="financial_need"
                  thousandsGroupStyle="thousand"
                  decimalSeparator={false}
                  value={this?.props?.oldStateValues?.values?.financial_need}
                  displayType="input"
                  className="loanAmountInput"
                  type="text"
                  isAllowed={this.withValueLimit}
                  onValueChange={this.onChangeFinancialNeed}
                  // isAllowed={({ floatValue }) => floatValue <= 20}
                  thousandSeparator={this?.props?.language === "en" ? "," : "."}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <div className="form-group">
              <label>
                <Translate content="label.timeDuration" />
              </label>
              <i
                data-tip
                data-for="time_duration"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="time_duration"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate content="label.timeDuration" />
                </ReactToolTip>
              </i>

              <div className="form-group-ranger">
                <Field
                  name="time_duration"
                  type="range"
                  component={inputSlider}
                  className="w-100"
                  id="time-duration"
                  max={60}
                  min={12}
                  step={1}
                  defaultValue={
                    this?.props?.oldStateValues?.values?.time_duration
                  }
                />

                <Field
                  name="time_duration"
                  type="number"
                  component={inputField}
                  min={12}
                  max={60}
                  step={1}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="form-group">
              <label>
                <Translate content="label.deadlineForOffer" />
              </label>
              <i
                data-tip
                data-for="DeadlineForOffer"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="DeadlineForOffer"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate content="tooltip.deadline" />
                </ReactToolTip>
              </i>
              <Field
                name="deadline"
                placeholder="dd.mm.yy"
                showTime={false}
                component={renderDatePicker}
                validate={validation.required}
                minDate={threeWeeksLater}
                currentDate={threeWeeksLater}
                disabled={this.props.pageEdit ? true : false}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <div className="form-group">
              <label>
                <Translate content="label.deadlineForPayment" />
              </label>
              <i
                data-tip
                data-for="DeadlineForPayment"
                className=" bx bx-info-circle ml-2"
              >
                <ReactToolTip
                  id="DeadlineForPayment"
                  type="info"
                  className="tooltip-width"
                >
                  <Translate content="tooltip.deadlineForPayment" />
                </ReactToolTip>
              </i>
              <Field
                name="payment_after"
                placeholder="dd.mm.yy"
                showTime={false}
                component={renderDatePicker}
                validate={validation.required}
                minDate={twoWeeksFromOfferDate}
                currentDate={twoWeeksFromOfferDate}
                disabled={this.props.pageEdit ? true : false}
              />
            </div>
          </div>
        </div>

        {this.props.errMsg ? displayServerErrors(this.props.errMsg) : null}
        {formSubmitFailError(this.props.submitFailed)}

        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 mt-4 backBtn">
            <button className="btn btn-secondary" type="button">
              <Translate content="label.back" />
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6 mt-4">
            <button
              className="btn btn-primary"
              style={{ float: "right" }}
              // onClick={this.props.onPageChange}
              type="submit"
            >
              <Translate content="label.continue" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default PageOneForm;
