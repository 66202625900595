import client from "../actions";
import { routes } from "_api/routes";
import { LIMIT } from "components/user/Signup";

export const fetchSuggestions = async (value) => {
  const response = await client.get(
    //   `https://api.opencorporates.com/v0.4/companies/search?q=diagonal.software`
    routes.fetchSuggestions + "?limit=" + LIMIT + "&company=" + value
  );

  return response.data.data;
};
