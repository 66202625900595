import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { fetchRatings } from "actions/ratings";
import PageThreeForm from "../forms/PageThreeForm";

class PageThreeEdit extends Component {
  render() {
    return <PageThreeForm {...this.props} pageEdit />;
  }
}

PageThreeEdit = reduxForm({
  form: "PageThreeEdit",
  destroyOnUnmount: false,
})(PageThreeEdit);

const selector = formValueSelector("PageThreeEdit");

const mapStateToProps = (state) => {
  const collateralCount = selector(state, "collateralCount");
  const creditCount = selector(state, "creditCount");

  const oldStateValues = { ...state?.form?.PageThreeEdit };
  const applicationDetails = { ...state?.fetchApplication?.application };
  const serviceAlternate =
    state.form.PageTwoEdit?.values.service_id?.value === 5;

  const initialValues = {
    sale_amount: applicationDetails?.sale_amount,
    profit_after_tax: applicationDetails?.profit_after_tax || 0,
    nda_requirement: applicationDetails?.nda_requirement,
    creditCount: applicationDetails.credit_ratings?.length || 1,

    collateralCount: applicationDetails?.collateralCount || 1,
    ...applicationDetails?.collateral_data,
  };

  if (!serviceAlternate && applicationDetails?.credit_ratings?.length > 0) {
    applicationDetails.credit_ratings.forEach((rating, index) => {
      initialValues[`cr_value_${index + 1}`] = rating?.value;

      initialValues[`cr_${index + 1}`] = {
        label: rating?.name[state?.language],
        value: rating?.code,
        id: rating?.id,
      };
    });
  }

  return {
    oldStateValues,
    creditCount,
    ratings: state.ratings.ratings.data,
    collateralCount,
    serviceAlternate,
    errMsg: state.errors,
    language: state.language,
    initialValues: { ...initialValues },
    formValues: state?.form?.PageThreeEdit?.values,
  };
};

export default connect(mapStateToProps, { fetchRatings })(PageThreeEdit);
