import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { getOfferReceived, productStatus } from "../../actions/products";
import { downloadFile } from "../../actions/download";
import Translate from "react-translate-component";
import Loading from "components/general/Loading";

class OfferReceived extends Component {
  state = { detail: null };
  componentDidMount() {
    const { application_id, product_id } = this.props.location.state;
    this.props.getOfferReceived(application_id, product_id);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.detail !== this.props.detail) {
      this.setState({ detail: this.props.detail.detail.data });
    }
  }
  showAttachments = files => {
    if (files.length === 0) {
      return <Translate content="label.noAttachmentsAvailable" />;
    } else {
      return files.map((file, index) => {
        return (
          <div className="file mb-2" key={index}>
            <span className="file-name">
              File {index + 1}
              <button
                className="btn btn-link"
                onClick={event => {
                  event.preventDefault();
                  this.props.downloadFile(
                    {
                      file_list: [
                        {
                          file_name: file.file_name,
                          file_path: file.path
                        }
                      ]
                    },
                    file.file_name
                  );
                }}
              >
                Download
              </button>
            </span>
            <span className="ml-4 file-size">Type: {file.file_type}</span>
          </div>
        );
      });
    }
  };

  render() {
    if (this.state.detail) {
      const { product_id, application_id, files, product } = this.state.detail;

      return (
        <Fragment>
          <div className="content-head row">
            <div className="content-head-left col-md-4">
              <h1 className="content-head__title">
                <Translate content="label.offerFrom" />{" "}
                {product.investor.company_name}
              </h1>
            </div>
            <div className="content-head-right col-md-8"></div>
          </div>
          <div className="content-body">
            <Loading />
            <div className="product-details">
              <div className="product-info-row">
                <Translate
                  content="label.coverLetter"
                  component="h6"
                  className="product__title"
                />
                <Translate
                  content="placeholder.notAvailable"
                  component="p"
                  className="product__info"
                />
              </div>
              <div className="product-info-row product-info-uploads">
                <Translate
                  content="label.attachments"
                  component="h6"
                  className="product__title"
                />
                {files ? this.showAttachments(files) : null}
                {files.length !== 0 ? (
                  <button
                    className="btn btn-link"
                    onClick={e => {
                      e.preventDefault();
                      this.props.downloadFile(
                        {
                          file_list: files.map(doc => ({
                            file_name: doc.file_name,
                            file_path: doc.path
                          }))
                        },
                        "attachments.zip"
                      );
                    }}
                  >
                    <Translate content="button.downloadAll" />
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex">
                <div className="item">
                  {/* <button className="btn btn-success mt-5 mr-3"
                                        onClick={() => this.props.productStatus(application_id, product_id, 'accepted', () => {
                                            this.props.history.push('/list-application')
                                        })}>Accept</button> */}
                  <Translate
                    content="button.accept"
                    component="button"
                    className="btn btn-success mt-5 mr-3"
                    onClick={() =>
                      this.props.productStatus(
                        application_id,
                        product_id,
                        "accepted",
                        () => {
                          this.props.history.push("/list-application");
                        }
                      )
                    }
                  />
                </div>
                <div className="item">
                  <Translate
                    content="button.reject"
                    component="button"
                    className="btn btn-danger mt-5"
                    onClick={() =>
                      this.props.productStatus(
                        application_id,
                        product_id,
                        "rejected",
                        () => {
                          this.props.history.push("/list-application");
                        }
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}
function mapStateToProps(state) {
  return { detail: state.offerReceived };
}
export default connect(mapStateToProps, {
  getOfferReceived,
  productStatus,
  downloadFile
})(OfferReceived);
