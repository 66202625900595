import React, { Component } from "react";

class Accordion extends Component {
  state = {
    isActive: false,
  };

  onClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  render() {
    return (
      <div className={"accordion"}>
        <button
          onClick={this.onClick}
          type="button"
          className={"accordion-title col-md-12"}
        >
          {this.props.title}
          <i
            style={{ marginLeft: "auto" }}
            className={
              this.state.isActive ? "bx bx-chevron-up" : "bx bx-chevron-down "
            }
          />
        </button>
        {this.state.isActive && (
          <div className={"accordion-content"}>{this.props.children}</div>
        )}
      </div>
    );
  }
}

export default Accordion;
