import client from "./index";
import { routes } from "./../_api/routes";
import { ERROR } from "../actions/types";
import { extractServerErrors } from "../_utils/validate";

export const downloadFile = (data, fileName) => async dispatch => {
  try {
    const response = await client.post(routes.downloadToken, data);
    const { token } = response.data;

    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${routes.downloadFile}?token=${token}`);

    xhr.responseType = "arraybuffer";
    xhr.onload = function(e) {
      if (this.status === 200) {
        let blob = new Blob([this.response], { type: "" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        if (fileName) link.download = fileName;
        link.click();
      }
    };
    xhr.send();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};
