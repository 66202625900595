export default {
  alert: {
    application_add:
      "Your credit request has been received. We will process your application and you will receive a reply shortly.",
  },
  button: {
    save: "Save",
    send: "Send",
    search: "Search",
    profile: "Profile",
    edit: "Edit",
    editProfile: "Edit Profile",
    reset: "Reset Password",
    logout: "Logout",
    signup: "Sign up now",
    submit: "Submit",
    login: "Login",
    accept: "Accept",
    reject: "Reject",
    downloadAll: "Download All",
  },
  column: {
    name: "Name",
    category: "Category",
    interest: "Interest",
    minimum_credit_amount: "Min Amount",
    maximum_credit_amount: "Max Amount",
    available_credit_amount: "Available Amount",
    status: "Status",
  },
  error: {
    notification: "Please refresh the page or contact to the support",
  },
  label: {
    youdont: "You don't have any notifications!",
    industry: "Industry",
    financialNeeds: "Loan Amount",
    yes: "Yes",
    no: "No",
    states: "Branch",
    country: "Country",
    NDARequirement: "NDA Requirement",
    ratingForCredit: "Rating for credit",
    ratingaggentur: "Rating agency",
    kreditrating: "Credit rating",
    fileUpload: "File Upload",
    balanceSheet: "Balance Sheet",
    latestBwa: "Latest BWA(Business assessment/management analysis)",
    tradeRegisterExcerpt: "Trade register excerpt",
    bylawsArticlesOfIncorporation: "Bylaws/articles of incorporation",
    financialPlan: "Financial plan (Alteast for next 3years)",

    balanceSheetProjectFinancing:
      "Balance sheet and income statement of the project company and all parent companies",
    latestBwaProjectFinancing: "Project presentation",
    tradeRegisterExcerptProjectFinancing:
      "Commercial register excerpt of the project company and all parent companies",
    bylawsArticlesOfIncorporationProjectFinancing:
      "Articles of association of the project company and all parent companies",
    financialPlanProjectFinancing: "Financial planning of the project",
    listOfShareholders: "List of shareholders",
    businessPlan: "Business plan",
    informationAboutOwner: "Information about owner",
    otherDocuments: "Other documents",
    weicheArt: "",
    wecant: "We cant verify your email address.",
    theconfirm: "The confirmation link has expired.",
    youcanask: "You can ask for a new validation link here.",
    weitereSicherheitenHinzufügen: "Add More Collateral",
    weitereRatingaggenturHinzufügen: "Add More Rating Agent",
    lastCollateralRemove: "Remove Last Collateral",
    lastRatingAgentRemove: "Remove Last Rating Agent",
    view: "View",
    addFileorDrop: "Add file or drop file here",
    service: "Service",
    duration: "Duration",
    deadline: "Deadline",
    deadlineForPayment: "Prefered Payment Date",
    deadlineForOffer: "Desire Offer Period",
    purpose: "Purpose",
    projectTotalSize: "Project Total Size",
    amountOfSales: "Sales Amount (last fiscal year)",

    profitAfterTaxes: "Profit After Taxes",
    projectMargin: "Project margin in EUR",
    collateraltitle: "Collateral?",
    collateral: "Collateral",
    typeOfCollateral: "Type of Collateral",
    value: "Value",
    appliedOn: "Applied on",
    appliedAmount: "Applied amount",
    awaitingApproval: "Awaiting approval",
    approved: "Approved",
    rejected: "Rejected",
    deleted: "Deleted",
    expired: "Expired",
    matchedProducts: "Matched Products",
    anonymizedInvestor: "Anonymized Investor",
    createdOn: "Created on",
    expiresOn: "Expires on",
    open: "Open",
    offerReceived: "Offer recieved",
    application: "Application",
    edit_application: "Edit application",
    creditType: "Credit Type",
    amount: "Amount",
    timeDuration: "Running Time",
    matches: "Matches",
    status: "Status",
    county: "County",
    first_name: "First Name",
    last_name: "Last Name",
    company_name: "Company Name",
    email_address: "Email Address",
    confirm_password: "Confirm Password",
    password: "Password",
    phone_number: "Phone number",
    terms: "terms and conditions.",
    terms_ending: " to.",
    iAgree: "I agree to the ",
    privacy_policy_ending: ".",
    and: " and ",
    justASecond: "Please wait a moment...",
    noMatches: "You do not have any matches.",
    noApplication: "You do not have any credit application.",
    otherContactPerson: "Further Contact Person",
    companyName: "Company Name",
    headquarter: "Headquarter",
    streetAddress: "Street and housenumber",
    postalCode: "Postal Code",
    contactPerson: "Contact Person",
    socialMedia: "Social Media",
    investorCategory: "Category",
    update: "Update",
    accountNotVerified: "Account not approved",
    accountNotConfirmed: "Account not confirmed yet!",
    accountNotComplete: "Profile incomplete!",
    accountRejected: "Account has been rejected!",

    sendit: "Send it again",
    didnt: "Didn’t recieve an email?",
    youraccounthas:
      "Your account has been successfully created. Please check your email for the confirmation",
    weve: "We’ve just send you an Email",
    activation: "Activation",
    adminapproval: "Admin Approval",
    confirmemail: "Confirm Email",
    ouradminneed:
      "Our admin needs to confirm the account first. We will send you an Email when its done",
    justonemore: "Just one more step, Admin Approval",
    youraccounthasbeen:
      "Your account has been activated. From now on you can use our service freely",
    wow: "Wow, Welcome to Pihub",
    forgotPassword: "Forgot Password?",
    enterEmailForgotten: "Enter your email address.",
    confirmationLink: "Confirmation Link",
    acceptNDA: "Accept NDA",
    passwordChangedSuccessful: "Successfully Password Changed",
    youCanUseYourNewPassword:
      "You can use your new password to log in to your creditech account.",
    withYourNewPassword: "with your new password",
    resetYourPassword: "Reset your password",
    enterAnEmailAssociated: "Enter an email associated with your account",
    changePassword: "Change Password?",
    attachments: "Attachments",
    coverLetter: "Cover letter",
    offerFrom: "Offer From",
    noAttachmentsAvailable: "No Attachments Available",
    weCantseem: "Sorry we can't seem to find the page you're looking for",
    takeMeHome: "Take me home",
    pleaseComplete: "Please complete your profile before you fill up form.",
    weJustSent: "We just sent",
    anEmailWithInstruction: "an email with instruction",
    continue: "Weiter",
    back: "Back",
    NDARequirementStatement:
      "If you wish to disclose certain information only in exchange for signing a confidentiality agreement, please tick in the box and sign the confidentiality agreement (above) and upload it here.",
  },
  placeholder: {
    please_select_your_company: "Please select your company",
    collateral_value: "Amount in EUR",
    select: "Select",
    and: "and ",
    privacy_policy_start: "Our ",
    privacy_policy: "privacy policy",
    privacy_policy_ending: " can be found here.",
    noActiveProduct: "You haven't invested on any products yet.",
    pleaseWait: "Einen Moment bitte...",
    noCreditRequests: "You do not have any credit request.",
    justASecond: "Please wait a moment...",
    selectAllStates: "Select All States",
    selectAllCounties: "Select All Counties",
    selectAll: "Select All",
    notAvailable: "Not available",
    changeProfilePic: "Change company picture",
    verified: "Verified",
    unverified: "Unverified",
    applicationAddSuccess: "Application Submitted Successfully!",
    applicationFailure: "Application quota exceeded",
    enterYourWebsite: "Enter your website URL",
    companyName: "Company Name inc.",
    headquarterLocation: "Headquarter Location",
    contactName: "Contact Name",
    contactName2: "Contact Name-2",
    contactName3: "Contact Name-3",
    contactEmail: "Contact Email",
    contactEmail2: "Contact Email-2",
    contactEmail3: "Contact Email-3",
    contactPhoneNo: "Contact phone number",
    contactPhoneNo2: "Contact phone number-2",
    contactPhoneNo3: "Contact phone number-3",
    facebookURL: "Facebook URL",
    twitterURL: "Twitter URL",
    LinkedinURL: "Linkedin URL",
    xingURL: "Xing URL",
    NewPassword: "New Password",
    Retypepassword: "Retype password",
    LandHouse: "Real estate/Investments/Business Shares/Rights",
    dashboard: "Dashboard",
    value: "Value",
    reasons:
      "Describe the purpose as clearly as possible. If the loan has more than one purpose, please state these.",
  },
  sidebar: {
    product: "Product",
    new_product: "New Product",
    invested_products: "Invested Products",
    products: "Products",
    credit_requested_products: "Credit Request",
    new_application: "Apply Now",
    my_application: "My Applications",
    application: "Application",
    applyNow: "Apply Now",
    corporate_information: "Corporate Information",
    apply_for_loan: "Apply for loan",
    dashboard: "Dashboard",
  },
  statuses: {
    debtorApplication: {
      requested: "Requested",
      open: "Waiting for Approval",
      questions_for_creditor: "Questions for Creditor",
      approved: "Approved",
      rejected: "Rejected",
      offer_rejected: "Offer Rejected",
      offer_accepted: "Offer Accepted",
      offer_received: "Offer Received",
      deleted: "Deleted",
      expired: "Expired",
      suspended: "Suspended",
      matches: {
        open: "Open",
        offer_rejected: "Offer Rejected",
        offer_accepted: "Offer Accepted",
        offer_received: "Offer Received",
        rejected: "Rejected",
      },
    },
  },
  text_blocks: {
    noAccount: "If you dont have account you can sign up ",
    here: "here.",
    detailsBelow: "Enter your details below",
    signUpAs: "Sign Up to Credit Tech as an Debtor",
    alreadyAMember: "Already have an account? ",
    enterCredentials: "Enter your email address and password",
    noProducts: "You do not have any products yet!",
    noData: "You do not have any data yet!",
    loading: "Loading... Please wait!",
    signUpTitle: "In a few steps to your financing.",
    signUpReason:
      "No matter whether you want to finance receivables, your warehouse, your trade - or acquire a competitor: Find your best financing in a few steps with one of our many lenders. Simply register and off you go!",
    loginTitle: "A few steps to your best credit",
    loginDescription:
      "Individual | All sectors | All credit products | from 1,000,000 EUR",
    creatYourLoan: "Create your loan application in just a few steps.",
    createYourLoanTailored:
      "You will then receive an individually tailored selection\n" +
      "                      of possible loans from us.",
    viewList: "Get an overview of your previous loan applications.",
    editLoanRequest: "Edit a loan request that has already been created.",
  },
  tooltip: {
    service_id:
      "Please choose a credit type. If you are not sure, our chat is available to assist you.",
    financial_need:
      "Please enter a credit amount between 250,000 EUR and 5,000,000 EUR.",
    time_duration: "Please enter a period between 12 months and 60 months.",
    purpose:
      "Please describe the purpose of the loan as clearly as possible. If the loan has more than one purpose, name them.",
    deadlineForPayment: "Min 2 weeks after desired offer period",
    deadline: "At least 3 weeks from today",
    collateral:
      "Do you have collateral that you can provide to collateralise the loan?",
    NDARequirement:
      "If you wish to disclose certain information only against signing a confidentiality agreement, click yes and sign the confidentiality agreement below and upload it below under other documents.",
    ratingForCredit:
      "If your company has a credit rating, please click on yes and enter the rating relevant to your company in the new window. Multiple entries are possible.",
    balanceSheet:
      "Please insert the balance sheet and G + V of the last three years.  ",
    tradeRegisteExcerpt:
      "Please insert a current extract from the commercial register which is not older than 6 months.",
    bylawsArticlesOfIncorporation: "Please insert your statute here.  ",
    financialPlan: "Ideally, the financial plan should cover the next 3 years.",
    latestBwa:
      "Please insert the current BWA and SuSa as well as those of the past fiscal year. ",
    listOfShareholders:
      "Please include a list of all shareholders. If a shareholder who is a legal entity holds more than 25% of your company, please send a list of shareholders of this company.",
    businessPlan:
      "Please send us your business plan. If you do not have one, a company presentation is sufficient.",
    informationAboutOwner: "Please send us more information about your owner.",
    otherDocuments:
      "Leases in connectionInfo field: 'Please send all other contracts and planning (such. as. urban development contracts, permits,in connection with the project development)'",
    phone_number: "Please enter your contact number selecting your country",
    password:
      " Your password must contain at least one capital letter, one special character and one number",
    email_address: "Enter your email address",
    company_name:
      "Please enter the name of your company here.  We do not process inquiries from private persons",
    last_name: "Please enter you family name",
    first_name: "Please enter your first name",
    amount_of_sales:
      "Please provide annual sales for the past full fiscal year",
    project_total_size:
      "Please provide the total cost of the project including all ancillary costs",
    project_size:
      "Please indicate the total cost of the project including all ancillary costs",
    profit_after_taxes:
      "Please state the annual turnover of the last complete business year ",
    project_margin: "Project Margin in EUR",
    county: "Please select county from the list below",
    industry: "Please select industry from the list below",
    states: "Please enter state from the list below",
    headquarter: "Please enter the state where your company is located.",
    street_address: "Please enter your company's street address. ",
    postal_code: "Please enter your company's postal code",
    contact_person:
      "Please enter the person's name, email address, and contact number",
    contact_person_2:
      "Please enter the second person's name, email address, and contact number",
    contact_person_3:
      "Please enter the third person's name, email address, and contact number",
    social_media: "Please enter URL for social media",
    file: "Upload any other important files if you have any.",
  },
  unconfirmed: {
    msg: "We’ve just send you an Email! Please check your email for the confirmation link.",
    emailSent: "We’ve just send you an Email!",
  },
  unverified: {
    msg: "Your account has not yet been activated by the administrator. As soon as this is done, you will receive an email and can then use all piHub features.",
  },
  rejected: {
    msg: "Your account has been rejected. Please contact the",
  },
  administrator: "administrator",

  validation: {
    payment_date: "Min 2 weeks after desired offer period",
    deadline_date: "At least 3 weeks from today",

    required: "* Required",
    email: "Invalid email address",
    phoneNumber: "Invalid phone number, must be 10 digits",
    number: "* Must be a number",
    same: "Password Mismatch",
    password:
      "Password must contain atleast one Capital letter, one small letter, a special character (@, #, $) , one numeric value and must be 8 digit long",
    newEmail: "Invalid Email!",
    pleaseAcceptTermsAndCondition: "Please accept terms and condition",
    fillAllRequiredFields: "Please fill all the required fields.",
    networkError: "Network Error",
    greater_than_financial: "Must be greater than 250.000",
  },
  steps: {
    step1: "Step 1",
    step2: "Step 2",
    step3: "Step 3",
    step4: "Step 4",
  },
  info: {
    profile_not_verified_info:
      "Your profile is not complete. Please complete your profile to be able to apply for credits.",
    file_upload_error_info:
      "Please note that a swift response is only possible with complete documents.",
    file_upload_info:
      "Please upload only PDF, images (jpg, jpeg, png) or Word files (docx or doc). The maximum file size is 10 MB.",
  },
};
