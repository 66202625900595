import { FETCH_APPLICATION } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_APPLICATION:
      return { ...state, application: action.payload };
    default:
      return state;
  }
}
