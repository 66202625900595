import React, { Component } from "react";
import { reduxForm } from "redux-form";

import { connect } from "react-redux";
import moment from "moment";
import PageOneForm from "../forms/PageOneForm";

const minLoanAmount = 500000;

class PageOne extends Component {
  render() {
    return <PageOneForm {...this.props} />;
  }
}

PageOne = reduxForm({
  form: "PageOne",
  destroyOnUnmount: false,
  validate,
})(PageOne);

function validate(values) {
  const error = {};
  const checkDeadline = moment(values.deadline).startOf("D").toDate();
  const threeWeeksLater = moment().add(21, "d").toDate();

  if (checkDeadline < threeWeeksLater) {
    error.deadline = "validation.deadline_date";
  }

  const twoweeksAfterDeadline = moment(values.deadline)
    .add(14, "d")
    .startOf("D")
    .toDate();

  const checkPaymentAfter = moment(values.payment_after).startOf("D").toDate();

  if (checkPaymentAfter < twoweeksAfterDeadline) {
    error.payment_after = "validation.payment_date";
  }
  return error;
}

const mapStateToProps = (state) => {
  const oldStateValues = { ...state?.form?.PageOne };
  const language = state?.language;

  const initialValues = {
    financial_need: minLoanAmount,
    time_duration: 12,
  };

  return {
    language,
    oldStateValues,
    errMsg: state.errors,
    initialValues: { ...initialValues },
  };
};

export default connect(mapStateToProps, {})(PageOne);
