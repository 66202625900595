import { LOADING, LOADING_DONE, CLEAR_LOADING } from "../actions/types";

let loadingStack = [];
let URL = null;
let method = null;
let data = null;

export default function(state = { loadingStack: [] }, action) {
  switch (action.type) {
    case LOADING:
      URL = action.payload;
      method = action.payload;
      data = action.payload;
      loadingStack = state.loadingStack;
      loadingStack[URL] = { URL, method, data };
      return { ...state, loadingStack: loadingStack };
    case LOADING_DONE:
      URL = action.payload;
      method = action.payload;
      data = action.payload;
      // @todo this state.loadingStack[URL]== { URL, method, data } needs to be checked some how.
      if (URL in state.loadingStack) {
        loadingStack = state.loadingStack;
        delete loadingStack[URL];
        return { ...state, loadingStack: loadingStack };
      } else {
        return state;
      }
    case CLEAR_LOADING:
      return { ...state, loadingStack: [] };
    default:
      return state;
  }
}
