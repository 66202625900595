import client from "./index";
import { routes } from "./../_api/routes";
import {
  NOTIFICATION_COUNT,
  ERROR,
  LIST_NOTIFICATION,
  LOADING,
  LOADING_DONE
} from "../actions/types";
import { extractServerErrors } from "../_utils/validate";

export const getNotificationCount = () => async dispatch => {
  dispatch({
    type: LOADING,
    payload: { URL: routes.countNotification, method: "get", data: null }
  });
  try {
    const response = await client.get(routes.countNotification);
    dispatch({
      type: NOTIFICATION_COUNT,
      payload: response.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.countNotification, method: "get", data: null }
    });
  }
};

export const getNotificationList = page => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.getNotificationList}?page=${page}`,
      method: "get",
      data: page
    }
  });
  try {
    const response = await client.get(
      `${routes.getNotificationList}?page=${page}`
    );
    dispatch({
      type: LIST_NOTIFICATION,
      payload: response.data.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.getNotificationList}?page=${page}`,
        method: "get",
        data: page
      }
    });
  }
};

export const markAsRead = (id, callback) => async dispatch => {
  try {
    let ids = { notification_ids: [id] };
    const response = await client.post(routes.markAsRead, ids);
    if (response) {
      callback();
    }
  } catch (e) {}
};
