import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { clearError, clearLoading } from "actions/clearError";
import { fetchServices } from "actions/services";
import { fetchStates, fetchCounties } from "actions/states";
import { fetchIndustries } from "actions/industries";

import PageTwoForm from "../forms/PageTwoForm";

class PageTwoEdit extends Component {
  render() {
    return <PageTwoForm {...this.props} pageEdit />;
  }
}

PageTwoEdit = reduxForm({
  form: "PageTwoEdit",
  destroyOnUnmount: false,
  // enableReinitialize: true,
})(PageTwoEdit);

const selector = formValueSelector("PageTwoEdit");

const mapStateToProps = (state) => {
  const selectedState = selector(state, "states");

  const applicationDetails = state?.fetchApplication?.application;

  const initialValues = {
    description: applicationDetails?.description,
    service_id: applicationDetails?.service_id,
    industry: applicationDetails?.industry,
    county: applicationDetails?.county,
    states: applicationDetails?.states,
  };

  const serviceAlternate =
    state.form.PageTwoEdit?.values?.service_id?.value === 5;

  return {
    errMsg: state.errors,
    states: state.states.states.data,
    counties: state.states.counties.data,
    language: state.language,
    services: state.services,
    industries: state.industries,
    selectedState,
    serviceAlternate,
    initialValues: { ...initialValues },
  };
};

export default connect(mapStateToProps, {
  fetchStates,
  fetchServices,
  clearError,
  fetchCounties,
  fetchIndustries,
  clearLoading,
})(PageTwoEdit);
