export default {
  alert: {
    application_add:
      "Ihr Kreditantrag ist eingegangen. Wir bearbeiten Ihren Antrag und Sie werden schon in Kürze eine Rückmeldung erhalten.",
  },
  button: {
    save: "Speichern",
    send: "Absenden",
    search: "Suche",
    profile: "Profil",
    edit: "Bearbeiten",
    editProfile: "Profil ändern",
    reset: "Passwort ändern",
    logout: "Logout",
    submit: "Absenden",
    signup: "Jetzt registrieren",
    login: "Anmelden",
    accept: "Annehmen",
    reject: "Ablehnen",
    downloadAll: "alle herunterladen",
  },
  column: {
    name: "Name",
    category: "Kategorie",
    interest: "Zinsen",
    minimum_credit_amount: "Mindestkreditbetrag",
    maximum_credit_amount: "Höchstkreditbetrag",
    available_credit_amount: "Kreditbetrag",
    status: "Status",
  },
  error: {
    notification: "Please refresh the page or contact to the support",
  },
  label: {
    youdont: "Sie haben keine Benachrichtigungen!",
    application: "Mein Kreditantrag",
    edit_application: "Mein Kreditantrag bearbeiten",
    creditType: "Kreditart",
    industry: "Branche",
    financialNeeds: "Kreditbetrag",
    purpose: "Verwendungszweck",
    projectTotalSize: "Projektgröße Gesamt",
    amountOfSales: "Jahresumsatz (letztes Geschäftsjahr)",
    profitAfterTaxes: "Gewinn nach Steuern",
    projectMargin: "Projektmarge in EUR",
    collateraltitle: "Sicherheiten vorhanden?",
    collateral: "Sicherheiten",
    typeOfCollateral: "Art der Sicherheit",
    value: "Wert",
    addMoreCollateral: "Weitere Sicherheit hinzufügen",
    lastCollateralRemove: "Letzte Sicherheit entfernen",
    lastRatingAgentRemove: "Letzte Ratingaggentur entfernen",
    ratingForCredit: "Kreditrating",
    ratingAgency: "Ratingagentur",
    creditRating: "Kreditrating",
    county: "Landkreis",
    timeDuratiion: "Laufzeit (in Monaten)",
    deadlineForPayment: "Gewünschter Auszahlungstermin",
    deadlineForOffer: "Gewünschte Angebotsfrist",
    NDARequirement: "Vertraulichkeitserklärung",
    yes: "Ja",
    no: "Nein",
    ratingaggentur: "Ratingaggentur",
    kreditrating: "Kreditrating",
    acceptNDA:
      "Hiermit bestätige ich die piHub-Vertraulichkeitserklärung gelesen zu haben und akzeptiere diese.",
    fileUpload: "Dokumente hochladen",

    balanceSheet: "Bilanz und G + V",
    bylawsArticlesOfIncorporation: "Satzung",
    latestBwa: "aktuelle BWA und SuSa",
    tradeRegisterExcerpt: "Handelsregisterauszug",
    financialPlan: "Finanzplanung",

    balanceSheetProjectFinancing:
      "Bilanz und G+V der Projektgesellschaft und sämtlicher Muttergesellschaften",
    bylawsArticlesOfIncorporationProjectFinancing:
      "Satzung der Projektgesellschaft und sämtlicher Muttergesellschaften",
    latestBwaProjectFinancing: "Projektpräsentation",
    businessPlan: "Geschäftsplan",
    otherDocuments: "Weitere Dokumente",
    tradeRegisterExcerptProjectFinancing:
      "Handelsregisterauszug der Projektgesellschaft und sämtlicher Muttergesellschaften",
    financialPlanProjectFinancing: "Finanzplanung Des Projektes",
    wecant: "Wir können Ihre E-Mail-Adresse nicht bestätigen.",
    theconfirm: "Der Bestätigungslink ist abgelaufen.",
    youcanask: "Hier können Sie einen neuen Bestätigungslink anfordern.",
    listOfShareholders: "Gesellschafterliste",
    informationAboutOwner: "Informationen zum wirtschaftlich Berechtigten",
    weicheArt: "Art?",
    weitereSicherheitenHinzufügen: "Weitere Sicherheiten hinzufügen",
    weitereRatingaggenturHinzufügen: "Weitere Ratingaggentur hinzufügen",
    view: "anzeigen",
    addFileorDrop: "Dokumente hinzufügen oder Dokumente hier ablegen",
    save: "Speichern",
    send: "Absenden",
    my_applications: "Meine Kreditanträge",
    service: "Kreditart",
    duration: "Laufzeit (in Monaten)",
    deadline: "Gewünschter Auszahlungstermin",
    appliedOn: "Antrag gestellt am",
    matches: "Passende Kredite",
    appliedAmount: "Kreditbetrag",
    status: "Status",
    requested: "Angefragt",
    approved: "Genehmigt",
    rejected: "Abgelehnt",
    deleted: "Gelöscht",
    expired: "Abgelaufen",
    awaitingApproval: "Angefragt",
    matchedProducts: "Übersicht Kreditgeber",
    anonymizedInvestor: "Kreditgeber",
    states: "Bundesland",
    createdOn: "Erstellt",
    expiresOn: "Fristablauf",
    open: "Angefragt",
    offerReceived: "Angebot erhalten",
    amount: "Kreditbetrag",
    timeDuration: "Laufzeit (in Monaten) ",
    first_name: "Vorname",
    last_name: "Nachname",
    company_name: "Name des Unternehmens",
    email_address: "Email-Adresse",
    confirm_password: "Passwort bestätigen",
    password: "Passwort",
    phone_number: "Telefonnummer",
    terms: "Geschäftsbedingungen",
    terms_ending: " zu.",
    iAgree: "Hiermit stimme ich den ",
    and: "zu und erkenne die  ",
    privacy_policy: "Datenschutz-Bestimmungen",
    privacy_policy_ending: " an.",
    justASecond: "Einen Moment bitte...",
    noMatches: "Sie haben keine Übereinstimmungen",
    noApplication: "Sie haben noch keinen Kreditantrag gestellt.",
    otherContactPerson: "Weitere Ansprechpartner",
    companyName: "Name der Firma",
    headquarter: "Land des Firmensitzes",
    streetAddress: "Straße und Hausnummer",
    postalCode: "Postleitzahl und Stadt",
    contactPerson: "Ansprechpartner",
    socialMedia: "Soziale Medien",
    investorCategory: "Kategorie",
    editProfile: "Profil bearbeiten",
    update: "ändern",
    accountNotVerified: "Konto noch nicht verifiziert",
    accountNotConfirmed: "Konto noch nicht bestätigt",
    accountNotComplete: "Profil nicht vollständig!",
    accountRejected: "Account wurde abgelehnt!",

    sendit: "Bestätigungslink erneut senden.",
    didnt: "Sie haben keine E-Mail erhalten?",
    youraccounthas:
      "Ihr Account wurde erfolgreich erstellt. Bitte überprüfen Sie Ihr E-Mail-Postfach, dort finden Sie einen Bestätigungslink.",
    weve: "Wir haben Ihnen eine E-Mail gesendet!",
    activation: "Aktivierung",
    adminapproval: "Administrator-Genehmigung",
    confirmemail: "Bestätigungs-E-Mail",
    ouradminneed:
      "Sobald Ihr Account freigeschaltet ist, werden wir Sie informieren.",
    justonemore: "Jetzt fehlt nur noch die Freigabe durch den Administrator!",
    youraccounthasbeen:
      "Ihr Account wurde freigeschaltet. Sie können Ab sofort alle piHub-Funktionen nutzen.",
    wow: "Herzlich Willkommen bei piHub!",
    forgotPassword: "Passwort vergessen?",
    enterEmailForgotten: "Geben Sie Ihre E-Mail-Adresse ein.",
    confirmationLink: "Bestätigungslink",
    passwordChangedSuccessful: "Erfolgreiche Passwortänderung",
    youCanUseYourNewPassword:
      "Mit Ihrem neuen Passwort können Sie sich bei Ihrem creditech-Konto anmelden.",
    withYourNewPassword: "mit deinem neuen Passwort",
    resetYourPassword: "Passwort ändern",
    enterAnEmailAssociated:
      "Gib die mit deinem Account verknüpfte Mail Adresse ein.",
    changePassword: "Du möchtest dein Passwort ändern?",
    attachments: "Anhänge",
    coverLetter: "Deckblatt",
    offerFrom: "Angebot von",
    noAttachmentsAvailable: " Kein Anhang verfügbar",
    weCantseem: "Die Seite die du aufrufen möchtest ist nicht verfügbar",
    takeMeHome: "Zurück zur Startseite",
    pleaseComplete:
      "Bitte füllen Sie Ihr Profil aus, bevor Sie das Formular ausfüllen.",
    weJustSent: "Wir haben dir gerade eine E-Mail geschickt.",
    anEmailWithInstruction:
      "Eine E-Mail mit einer Anleitung zum Zurücksetzen Ihrer E-Mail wurde an folgende Adresse gesendet",
    continue: "Weiter",
    back: "Zurück",
    NDARequirementStatement:
      "Wenn Sie bestimmte Informationen nur gegen Unterzeichnung einer Vertraulichkeitserklärung weitergeben möchten, kreuzen Sie bitte das Kästchen an, unterzeichnen Sie die Vertraulichkeitserklärung (siehe oben) und laden Sie sie hier hoch.",
  },
  placeholder: {
    please_select_your_company: "Bitte wählen Sie Ihr Unternehmen",
    collateral_value: "Wert in EUR",
    select: "Auswählen",
    and: "zu und erkenne die ",
    privacy_policy_start: "Unsere ",
    privacy_policy: "Datenschutzhinweise",
    privacy_policy_ending: " finden Sie hier.",
    noActiveProduct: "Sie haben noch keine aktiven Kredite.",
    noCreditRequests: "Dieses Produkt hat keine Kreditanfrage.",
    justASecond: "Einen Moment bitte...",
    selectAllStates: "deutschlandweit",
    selectAllCounties: "Alle Landkreis auswählen",
    selectAll: "Alle auswählen",
    notAvailable: "Nicht verfügbar.",
    changeProfilePic: "Firmenbild ändern",
    verified: "Bestätigt",
    unverified: "Nicht bestätigt",
    applicationAddSuccess: "Antrag erfolgreich eingereicht!",
    applicationFailure: "Ihre Antragsquote ist überschritten!",
    enterYourWebsite: "Geben Sie bitte Ihre Homepage URL ein.",
    companyName: "Firmenname",
    headquarterLocation: "Firmensitz",
    contactName: "Name des Ansprechpartners",
    contactName2: "Name des Ansprechpartners-2",
    contactName3: "Name des Ansprechpartners-3",
    contactEmail: "Mail Adresse des Ansprechpartners",
    contactEmail2: "Mail Adresse des Ansprechpartners-2",
    contactEmail3: "Mail Adresse des Ansprechpartners-3",
    contactPhoneNo: "Telefonnummer des Ansprechpartners",
    contactPhoneNo2: "Telefonnummer des Ansprechpartners-2",
    contactPhoneNo3: "Telefonnummer des Ansprechpartners-3",
    facebookURL: "Facebook URL",
    twitterURL: "Twitter URL",
    LinkedinURL: "Linkedin URL",
    xingURL: "Xing URL",
    NewPassword: "Neues Passwort",
    Retypepassword: "Gib dein Passwort erneut ein",
    LandHouse: "Immobilien/Beteiligungen/Geschäftsanteile/Rechte",
    reasons:
      "Beschreiben Sie den Zweck so genau wie möglich. Wenn das Darlehen mehr als einen Zweck hat, geben Sie diese bitte an.",
  },
  sidebar: {
    product: "Produkt",
    new_product: "Neues Produkt",
    invested_products: "Investierte Produkte",
    products: "Produkte",
    credit_requested_products: "Kreditantrag",
    new_application: "Neue Kreditantrag",
    my_application: "Meine Kreditanträge",
    application: "Kreditantrag",
    applyNow: "Kreditantrag erstellen",
    my_applications: "Meine Kreditanträge",
    corporate_information: "Mein Unternehmen",
    apply_for_loan: "Kreditantrag erstellen",
    dashboard: "Dashboard",
  },
  statuses: {
    debtorApplication: {
      requested: "Angefragt",
      open: "Warten auf die Annahme",
      questions_for_creditor: "Rückfrage Kreditor",
      approved: "Akzeptiert",
      rejected: "Abgelehnt",
      offer_rejected: "Angebot abgelehnt",
      offer_accepted: "Angebot akzeptiert",
      offer_received: "Angebot erhalten",
      deleted: "Gelöscht",
      expired: "Abgelaufen",
      suspended: "Ausgesetzt",
    },
    matches: {
      open: "offen",
      offer_rejected: "Angebot abgelehnt",
      offer_accepted: "Angebot akzeptiert",
      offer_received: "Angebot erhalten",
      rejected: "Abgelehnt",
    },
  },
  text_blocks: {
    noAccount: "Wenn Sie noch kein Konto haben, können Sie sich ",
    here: "hier anmelden.",
    detailsBelow: "In wenigen Schritten zu Ihrem unternehmerischen Ziel!",
    signUpAs: "Melden Sie sich in wenigen Minuten an",
    alreadyAMember: "Sie haben schon ein Konto? ",
    enterCredentials: "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein.",
    noProducts: "Sie haben noch keine Kreditprodukte angelegt.",
    noData: "Sie haben noch keine Daten hinterlegt.",
    loading: "Die Seite lädt.. Bitte warten!",
    signUpTitle: "In wenigen Schritten zu Ihrer Finanzierung.",
    signUpReason:
      "Ganz unabhängig davon, ob Sie Forderungen, Ihr Warenlager, Ihren Handel finanzieren möchten – oder einen Mitbewerber akquirieren möchten: Finden Sie Ihre beste Finanzierung in wenigen Schritten mit einem unserer vielen Kreditgeber. Einfach registrieren und los geht’s!",
    loginTitle: "In wenigen Schritten zu Ihrem unternehmerischen Ziel!",
    loginDescription:
      "Individuell | Alle Branchen | Alle Kreditprodukte | ab 1.000.000 EUR",
    creatYourLoan: "Erstellen Sie Ihre Kreditanfrage in wenigen Schritten.",
    createYourLoanTailored:
      "Sie werden von uns eine Reihe von Maßgeschneiderten Vorschlägen erhalten.",
    viewList:
      "Bekommen Sie eine Übersicht über Ihre bisherigen Kreditanfragen.",
    editLoanRequest: "Bearbeiten Sie eine bisherige Kreditanfrage.",
  },
  tooltip: {
    service_id: "Bitte entscheiden Sie sich für eine Kreditart.",
    financial_need: "Bitte geben Sie einen Kreditbetrag ab 500.000 EUR ein.",
    time_duration:
      "Bitte geben Sie eine Laufzeit zwischen 12 Monaten und 60 Monaten ein.",
    purpose:
      "Bitte beschreiben Sie den Verwendungszweck für Ihren Kredit möglichst eindeutig. Falls der Kredit mehrere Verwendungszwecke haben sollte, dann benennen Sie diese.  ",
    deadlineForPayment: "Min 2 Wochen nach Angebotsfrist.",
    deadline: "Mind. 3 Wochen ab heute.",
    collateral:
      "Haben Sie Sicherheiten, welche Sie zur Besicherung des Kredites zur Verfügung stellen können?",
    NDARequirement:
      "Falls Sie bestimmte Informationen nur gegen die Unterzeichnung einer Vertraulichkeitsvereinbarung offenlegen möchten, klicken Sie auf „ja“ und unterzeichnen Sie die untenstehende Vertraulichkeitsvereinbarung und laden Sie diese unter „sonstige Dokumente“ hoch.",
    ratingForCredit:
      "Falls Ihr Unternehmen ein Kreditrating hat, dann klicken Sie bitte auf „ja“ und geben Sie dann im neuen Fenster das für Ihr Unternehmen relevante Rating an. Mehrfachangaben sind möglich.",
    balanceSheet:
      "Bitte fügen Sie die Bilanz und G + V der letzten drei Jahre ein. ",
    tradeRegisteExcerpt:
      "Bitte fügen Sie einen aktuellen Handelsregisterauszug ein, der nicht älter als 6 Monate ist.",
    bylawsArticlesOfIncorporation: "Bitte fügen Sie Ihre Satzung hier ein. ",
    financialPlan:
      "Der Finanzplan sollte mindestens die nächsten 3 Jahre umfassen.",
    latestBwa:
      "Bitte fügen Sie die aktuell vorliegende BWA und SuSa ein sowie die des vergangenen Geschäftsjahres. ",
    listOfShareholders:
      "Bitte fügen Sie eine Liste mit allen Gesellschaftern ein. Sollte ein Gesellschafter, der eine juristische Person ist, mehr als 25% an Ihrer Gesellschaft halten, dann senden Sie bitte noch eine Gesellschafterliste von dieser Gesellschaft.",
    businessPlan:
      "Bitte senden Sie uns Ihren Geschäftsplan. Sollten Sie keinen haben, ist eine Unternehmenspräsentation ausreichend.",
    informationAboutOwner:
      "Bitte senden Sie uns den Personalausweis oder Reisepass des wirtschaftlich Berechtigten.",
    otherDocuments:
      "Bitte senden Sie sämtliche weitere Unterlagen, die Sie für relevant halten. (Unternehmenspräsentation oder Ähnliches))",
    phone_number:
      "Bitte geben Sie Ihre Kontaktnummer ein und wählen Sie Ihr Land aus",
    password:
      "Passwort muß mindestens einen Großbuchstaben, ein Sonderzeichen und eine Zahl haben.",
    email_address: "Geben Sie Ihre E-Mail-Adresse ein.",
    company_name:
      "Name des Unternehmens Bitte geben Sie hier den Namen Ihres Unternehmens an. Wir bearbeiten keine Anfragen von Privatpersonen.",
    last_name: "Bitte geben Sie Ihren Familiennamen ein.",
    first_name: "Bitte geben Sie Ihren Vornamen ein.",
    project_total_size:
      "Bitte geben Sie die Gesamtkosten des Projekts einschließlich aller Nebenkosten an.",
    amount_of_sales:
      "Bitte Jahresumsatz des vergangenen vollständigen Geschäftsjahres angeben.",

    project_size:
      "Bitte Gesamtkosten des Projektes inklusive sämtlicher Nebenkosten angeben.",
    profit_after_taxes:
      "Bitte den Jahresgewinn des letzten Geschäftsjahres nach Steuern angeben.",
    project_margin: "Projektmarge in EUR.",

    county: "Bitte wählen Sie Ihren Landkreis aus.",
    industry: "Bitte wählen Sie eine Branche aus der folgenden Liste.",
    states: "Bitte wählen Sie Ihr Bundesland aus.",
    headquarter:
      "Bitte geben Sie den Staat an, in dem Ihr Unternehmen ansässig ist.",
    street_address:
      "Bitte geben Sie die Straße und Hausnummer Ihres Unternehmenssitzes an. ",
    postal_code:
      "Bitte geben Sie die Postleitzahl und Stadt Ihres Unternehmenssitzes an.",
    contact_person:
      "Bitte geben Sie den Namen, die E-Mail-Adresse und die Telefonnummer des Ansprechpartners an.",
    contact_person_2:
      "Bitte geben Sie den Namen, die E-Mail-Adresse und die Telefonnummer des zweiten Ansprechpartners an.",
    contact_person_3:
      "Bitte geben Sie den Namen, die E-Mail-Adresse und die Telefonnummer des dritten Ansprechpartners an.",
    social_media: "Bitte URL zu den Social Media Kanälen eingeben.",
    file: "Laden Sie alle anderen wichtigen Dateien hoch, wenn Sie welche haben.",
  },
  unconfirmed: {
    msg: "Wir haben Ihnen eine E-Mail gesendet! Bitte überprüfen Sie Ihr E-Mail-Postfach, dort finden Sie einen Bestätigungslink.",
    emailSent: "Wir haben Ihnen eine E-Mail gesendet!",
  },
  unverified: {
    msg: "Ihr Konto wurde noch nicht durch den Administrator freigeschaltet. Sobald dies geschehen ist, erhalten Sie eine E-Mail und können dann alle piHub-Funktionen nutzen.",
  },

  rejected: {
    msg: "Ihr Account wurde abgelehnt, bitte kontaktieren Sie den.",
  },

  administrator: "admin",

  validation: {
    payment_date: "Min 2 Wochen nach Angebotsfrist.",
    deadline_date: "Mind. 3 Wochen ab heute.",
    required: "* Pflichtfeld",
    email: "Ungültige E-Mail-Adresse.",
    phoneNumber: "Ungültige Telefonnummer, muss 10-stellig sein.",
    number: "* Muss eine Nummer sein.",
    same: "Ihre Passworteingaben stimmen nicht überein.",
    password:
      "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen (@, #, $) und einen Zahlenwert enthalten und aus 8 Ziffern bestehen.",
    newEmail: "Ungültige E-Mail!",
    pleaseAcceptTermsAndCondition:
      "Bitte stimmen Sie den Geschäftsbedingungen zu.",
    fillAllRequiredFields: "Bitte füllen Sie alle Pflichtfelder aus.",
    networkError: "Netzwerkfehler",
    greater_than_financial: "Muss größer als 500.000 sein",
  },
  steps: {
    step1: "Schritt 1",
    step2: "Schritt 2",
    step3: "Schritt 3",
    step4: "Schritt 4",
  },
  info: {
    profile_not_verified_info:
      "Ihr Profil ist nicht vollständig. Bitte vervollständigen Sie Ihr Profil um Kreditanfragen zu stellen.",
    file_upload_error_info:
      "Bitte beachten Sie, daß eine schnelle Bearbeitung nur mit vollständigen Unterlagen möglich ist.",

    file_upload_info:
      "Bitte laden Sie nur PDF, Bilder (jpg, jpeg, png) oder Word-Dateien (docx oder doc) hoch. Die maximale Dateigröße beträgt 10 MB.",
  },
};
