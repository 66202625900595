import { combineReducers } from "redux";
// import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import user from "./user";
import notificationCount from "./notificationCount";
import notificationList from "./notificationList";
import errors from "./errors";
import error_fields from "./errorfields";

import pagination from "./pagination";
import industryReducer from "./industryReducer";
import applicationReducer from "./applicationReducer";
import ratingReducer from "./ratingReducer";
import statesReducer from "./statesReducer";
import language from "./language";
import servicesReducer from "./servicesReducer";
import profile from "./profile";
import products from "./products";
import fetchApplication from "./fetchApplication";
import offerReceived from "./offerReceived";
import scope from "./scope";
import flashMessage from "./flashMessage";
import loading from "./loading";
import formReducer from "./formReducer";
import addApplicationPageState from "./addApplicationPageState";

const rootReducer = combineReducers({
  user,
  auth,
  profile,
  language,
  products,
  errors,
  notificationCount,
  notificationList,
  offerReceived,
  pagination,
  scope,
  form: formReducer,
  error_fields,
  industries: industryReducer,
  applicationDetail: applicationReducer,
  fetchApplication,
  ratings: ratingReducer,
  states: statesReducer,
  services: servicesReducer,
  flashMessage,
  loading,
  addApplicationPageState,
});

export default rootReducer;
